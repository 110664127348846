import 'react-multi-email/style.css';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { Button, Modal } from 'reactstrap';

import * as shareApi from '../../../api/share';

import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { Share } from '../Share';
import { ShareAboutType, ShareTabType } from '../Share.helper';

const ShareAboutObjectNameMap = {
	[ShareAboutType.SURVEY]: 'surveyTemplate',
	[ShareAboutType.VIDEO]: 'video',
	[ShareAboutType.POST]: 'post',
	[ShareAboutType.CHANNEL]: 'channel',
	[ShareAboutType.STUDIO]: 'studio',
};

export const ShareModal = ({
	channel,
	content,
	contentType,
	defaultShareMessage,
	roles,
	studio,
	tabs,
	toggle,
}) => {
	const handleShares = async (data, role) => {
		const shares = data.map((share) => ({
			about: {
				[ShareAboutObjectNameMap[contentType]]: content._id,
				role,
				type: contentType,
			},
			recipient: {
				type: share.type,
				email: share.email || undefined,
				user: share.user?._id || undefined,
				phoneNumber: share.phoneNumber || undefined,
				group: share.group?._id || undefined,
			},
			message: share.message || '',
		}));
		await shareApi.share(shares);
	};

	const shareProps = {
		[ShareAboutObjectNameMap[contentType]]: content,
		channel,
		defaultMessage: defaultShareMessage,
		onShare: handleShares,
		roles,
		shareTabTypes: tabs,
		studio,
	};

	return (
		<Modal
			centered
			contentClassName="border-0 shadow-lg rounded overflow-hidden bg-darker m-auto"
			isOpen
			toggle={toggle}
			zIndex={2000}
		>
			<div className="rounded overflow-hidden">
				<Button
					color="neutral-secondary"
					className="SettingModal_close position-absolute card-badges shadow btn-pill d-30 p-0 m-2"
					onClick={toggle}
				>
					<span className="btn-wrapper--icon">
						<FaTimes />
					</span>
				</Button>
			</div>
			<div className="p-5 pb-4">
				<Share {...shareProps} />
			</div>
		</Modal>
	);
};

ShareModal.propTypes = {
	channel: PropTypes.shape({}),
	content: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}).isRequired,
	contentType: PropTypes.oneOf(Object.values(ShareAboutType)).isRequired,
	defaultShareMessage: PropTypes.string,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))),
	studio: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	tabs: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ShareTabType))).isRequired,
	toggle: PropTypes.func.isRequired,
};

ShareModal.defaultProps = {
	channel: undefined,
	defaultShareMessage: '',
	roles: [],
	studio: undefined,
};
