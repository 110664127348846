import PropTypes from 'prop-types';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Path, getLink } from '../../RoutePath';
import { useFetchGraphGlobalsPrices } from '../../api-hooks/graph/globals';
import { useCreateInstantStudio } from '../../api-hooks/studio/schedule';
import { useSidebar } from '../../layout-components/Sidebar/Provider';
import { usePoints } from '../Membership/Points';
import { PaginationProvider } from '../Pagination/Provider';
import { StudioLoadingType } from '../Studio/LoadingType';
import { StudioScheduleCalendarModal } from './Calendar/Modal/Modal';
import { StudioScheduleContext, StudioScheduleModal } from './Context';
import { StudioScheduleManagerEditModal } from './Manager/EditModal';
import { StudioScheduleManagerEstimation } from './Manager/Estimation';
import { StudioScheduleManagerModal } from './Manager/Modal/Modal';

const defaultFormData = {
	isPublic: false,
	isRecordEnabled: false,
};

const DEFAULT_PRICES = {
	studioMinute: 2,
	studioMinuteIfRecording: 6,
	viewerMinute: 1,
	participantMinute: 1,
	studioStart: 6,
};

export const StudioScheduleProvider = ({ children }) => {
	const history = useHistory();
	const { balanceWithChips } = usePoints();
	const [modalOpen, setModalOpen] = useState();
	const [modalAlertMessage, setModalAlertMessage] = useState();
	const [modalOptions, setModalOptions] = useState();
	const [modalEstimationOpen, setModalEstimationOpen] = useState();
	const [formData, setFormData] = useState(defaultFormData);
	const [studioInEditionId, setStudioInEditionId] = useState();
	const [studioOwnerId, setStudioOwnerId] = useState();
	const [studioLoadingType, setStudioLoadingType] = useState(StudioLoadingType.MEETING);
	const { toggleSidebarMenu } = useSidebar();

	const openModal = useCallback((modalType, studioId, message, options) => {
		setModalOpen(modalType);
		setModalAlertMessage(message);
		setModalOptions(options);
		setStudioInEditionId(studioId);
	}, []);
	const { data: PRICES = DEFAULT_PRICES } = useFetchGraphGlobalsPrices();

	const {
		mutateAsync: createInstantStudioAsync,
		isLoading: instantStudioLoading,
	} = useCreateInstantStudio();

	const canStartStudio = (
		PRICES?.studioStart > 0
		&& balanceWithChips > 0
		&& PRICES.studioStart <= balanceWithChips
	);

	const closeModal = useCallback(() => {
		setModalOpen(undefined);
		setModalAlertMessage(undefined);

		if (modalOptions?.openCalendarAfterClose) {
			setModalOpen(StudioScheduleModal.CALENDAR);
		}
		setModalOptions(undefined);

		setStudioInEditionId(undefined);
		setModalEstimationOpen(false);
		setFormData(false);
	}, [modalOptions]);

	const openModalEstimation = useCallback((recordEnabled) => {
		setFormData(recordEnabled);
		setModalEstimationOpen(true);
	}, []);

	const closeModalEstimation = useCallback(() => {
		setModalEstimationOpen(false);
		setFormData(false);
	}, []);

	const handleScheduleStudioSuccess = useCallback((studioSaved) => {
		closeModal();
		const link = getLink(
			Path.STUDIO_HOST,
			{ hashtag: studioSaved.owner.hashtag, code: studioSaved.code },
		);
		history.push(link);
		toggleSidebarMenu();
	},
	[closeModal, history, toggleSidebarMenu]);

	const startInstantStudio = useCallback(async (channelId, isPublic) => {
		setStudioLoadingType(
			isPublic
				? StudioLoadingType.PUBLIC_LIVE
				: StudioLoadingType.MEETING,
		);

		return createInstantStudioAsync(
			{ channelId, studioSchedule: { isPublic } }, {
				onSuccess: (response) => handleScheduleStudioSuccess(response),
				onError: (error) => {
					openModal(
						StudioScheduleModal.EDIT,
						undefined,
						undefined,
						{
							initialError: error,
							forceStartImmediate: true,
							isPublic,
						},
					);
				},
			},
		);
	}, [
		createInstantStudioAsync,
		handleScheduleStudioSuccess,
		openModal,
	]);

	const context = useMemo(() => ({
		canStartStudio,
		closeModal,
		modalAlertMessage,
		modalOpen,
		openModal,
		setModalAlertMessage,
		modalEstimationOpen,
		openModalEstimation,
		closeModalEstimation,
		setModalOpen,
		setStudioInEditionId,
		studioInEditionId,
		PRICES,
		startInstantStudio,
		instantStudioLoading,
		studioLoadingType,
	}), [
		canStartStudio,
		closeModal,
		modalAlertMessage,
		modalOpen,
		openModal,
		setModalAlertMessage,
		modalEstimationOpen,
		openModalEstimation,
		closeModalEstimation,
		setModalOpen,
		setStudioInEditionId,
		studioInEditionId,
		PRICES,
		startInstantStudio,
		instantStudioLoading,
		studioLoadingType,
	]);

	return (
		<StudioScheduleContext.Provider value={context}>
			{children}
			<Suspense fallback={null}>
				{modalOpen === StudioScheduleModal.EDIT && (
					<StudioScheduleManagerEditModal
						isOpen
						options={modalOptions}
						studioOwnerId={studioOwnerId}
					/>
				)}
				{modalOpen === StudioScheduleModal.MANAGE && (
					<PaginationProvider>
						<StudioScheduleManagerModal
							defaultTab={modalOptions?.defaultTab}
							isOpen
							studioOwnerId={studioOwnerId}
							setStudioOwnerId={setStudioOwnerId}
						/>
					</PaginationProvider>
				)}
				{modalOpen === StudioScheduleModal.CALENDAR && (
					<StudioScheduleCalendarModal
						isOpen
						studioOwnerId={studioOwnerId}
						setStudioOwnerId={setStudioOwnerId}
						options={modalOptions}
					/>
				)}
				{modalEstimationOpen && (
					<StudioScheduleManagerEstimation
						isOpen
						onClose={closeModalEstimation}
						closeAllModals={closeModal}
						formData={formData}
					/>
				)}
			</Suspense>
		</StudioScheduleContext.Provider>
	);
};

StudioScheduleProvider.propTypes = {
	children: PropTypes.node,
};

StudioScheduleProvider.defaultProps = {
	children: undefined,
};
