import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { useOpenNotificationsModal } from '../../lib/serviceWorkerHook';

import { Path, getLink } from '../../RoutePath';
import { useAuthentication } from '../Authentication/Authentication';
import { useProfile } from '../Profile/ProfileContext';
import { StudioStatus } from '../StudioSchedule/helper';
import { StudioButton } from './Button';
import { BuyMore } from './BuyMore';
import { CompleteProfile } from './CompleteProfile';
import { DonatePoints } from './DonatePoints';
import { useCurrentStudio } from './useCurrentStudio';

export const StudioHeader = ({ isSearchOpen }) => {
	const { t } = useTranslation();
	const { isLoggedIn } = useAuthentication();
	const openNotificationsModal = useOpenNotificationsModal();
	const {
		currentEstimation,
		isCurrentEstimationBellowLimit,
		isCurrentStudioStarted,
		isCurrentStudioMine,
		isCurrentStudioOperatedByMe,
		currentStudio,
	} = useCurrentStudio();

	const showBuyPoints = (isCurrentStudioMine || isCurrentStudioOperatedByMe)
		&& !!currentEstimation
		&& isCurrentEstimationBellowLimit;

	const { profile } = useProfile();

	const isOperatorRoute = useRouteMatch({ path: getLink(
		Path.STUDIO_OPERATOR,
		{ hashtag: currentStudio?.owner?.hashtag, code: currentStudio?.code },
	) });

	const isStudioControlled = isCurrentStudioStarted
		&& (isCurrentStudioMine || (isCurrentStudioOperatedByMe && isOperatorRoute?.isExact));

	const isParticipantRoute = useRouteMatch({ path: getLink(
		Path.STUDIO_PARTICIPANT,
		{ hashtag: currentStudio?.owner?.hashtag, code: currentStudio?.code },
	) });
	const showLiveStatus = isStudioControlled || isParticipantRoute?.isExact;

	const isStudioRunning = currentStudio?.status === StudioStatus.RUNNING;
	const isRecording = isStudioRunning && currentStudio?.isRecording;
	const canStartStudio = profile?.organizationRole?.canStartStudio;

	useEffect(() => {
		if (isLoggedIn) {
			openNotificationsModal();
		}
	}, [isLoggedIn, openNotificationsModal]);

	return (
		<div
			className={clsx('StudioHeader d-flex align-items-center text-second h-100 flex-shrink-0 ml-auto',
				{ 'search-menu-open': isSearchOpen },
				{ 'search-menu-close': !isSearchOpen })}
		>
			{showBuyPoints && !profile?.completedRewardClaimed && <CompleteProfile />}
			{showBuyPoints && (
				<span className="d-none d-lg-block">
					{isCurrentStudioMine
						? <BuyMore />
						: <DonatePoints channel={currentStudio.owner} />}
				</span>
			)}
			{showLiveStatus && (
				<div className="d-flex align-items-center">
					{isRecording && (
						<Badge className="mr-2" color="danger" title={t('StudioHeader.recording')}>{t('StudioHeader.rec')}</Badge>
					)}
				</div>
			)}
			{isLoggedIn && canStartStudio && <StudioButton />}
		</div>
	);
};

StudioHeader.propTypes = {
	isSearchOpen: PropTypes.bool,
};

StudioHeader.defaultProps = {
	isSearchOpen: false,
};
