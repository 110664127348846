/* eslint-disable react/prop-types */
// @ts-check

import React, { useCallback } from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useCapturePaypalDonationSubscription, useCreatePaypalDonationSubscription } from '../../../../api-hooks/channel/donation/subscription';

const { VITE_PAYPAL_CLIENT_ID } = import.meta.env;

/**
 * @typedef {{
 * 	amount: number,
 * 	channelId: string,
 * 	includeFee: boolean,
 * 	goalId: string,
 * 	currency: string,
 * }} DonateSubscriptionPaypalButtonProps
 */

/**
 * @typedef {{
 * 	amount: number,
 *  channelId: string,
 *  includeFee: boolean,
 *  goalId?: string | undefined
 * }} DonationSubscriptionCreate
 */

/**
 * @typedef {Object} OnPaypalApproveData
 * @property {string} orderID
 * @property {string | null | undefined} payerID
 * @property {string} paymentID
 * @property {string} billingToken
 * @property {string} facilitatorAccessToken
 * @property {string} subscriptionID
 */

export const DonateSubscriptionPaypalButton = (
	/** @type {DonateSubscriptionPaypalButtonProps} */
	{
		amount,
		channelId,
		includeFee,
		goalId,
		currency,
	},
) => {
	const { mutateAsync: createSubscriptionAsync } = useCreatePaypalDonationSubscription();
	const { mutateAsync: captureSubscriptionAsync } = useCapturePaypalDonationSubscription();

	const handleCreateSubscription = useCallback(async () => {
		const subscription = await createSubscriptionAsync(
			/** @type {DonationSubscriptionCreate} */
			{ amount, channelId, includeFee, goalId },
		);
		return subscription.id;
	}, [amount, channelId, createSubscriptionAsync, goalId, includeFee]);

	const handleApproveSubscription = useCallback(async (
		/** @type {OnPaypalApproveData} */
		data,
	) => {
		await captureSubscriptionAsync(
			{ paypalSubscriptionId: data.subscriptionID, includeFee, goal: goalId, currency },
		);
	}, [captureSubscriptionAsync, goalId, includeFee, currency]);

	return (
		<div className="p-2 bg-white rounded w-100">
			<PayPalScriptProvider
				options={{
					'client-id': VITE_PAYPAL_CLIENT_ID,
					components: 'buttons',
					intent: 'subscription',
					vault: true,
				}}
			>
				<PayPalButtons
					createSubscription={handleCreateSubscription}
					style={{
						label: 'subscribe',
						color: 'blue',
					}}
					onApprove={handleApproveSubscription}
					forceReRender={[handleCreateSubscription]}
				/>
			</PayPalScriptProvider>
		</div>
	);
};
