import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaPaperPlane } from 'react-icons/fa';
import { Button } from 'reactstrap';

import { useProfile } from '../Profile/ProfileContext';
import { ShareAboutType, ShareTabType } from '../Share/Share.helper';
import { ShareModalButton } from '../Share/Modal/Button';

export const ShareVideoButton = ({
	video,
	miniPlayer,
}) => {
	const { t } = useTranslation();
	const { profile } = useProfile();

	return (
		<div id={`SettingsLibraryVideosListActions_${video._id}`}>
			<ShareModalButton
				buttonComponent={Button}
				channel={profile}
				className={`p-0 btn-no-focus border-0 flex-shrink-0 mx-1 ${miniPlayer ? 'd-25' : 'd-30'}`}
				color="outline-white"
				content={video}
				contentType={ShareAboutType.VIDEO}
				tabs={[
					ShareTabType.USERS_FOLLOWERS_FRIENDS,
					ShareTabType.EMAIL,
					ShareTabType.LINK,
					ShareTabType.SMS,
					ShareTabType.POST,
				]}
				title={t('Videos.ListActions.share')}
			>
				<span className="btn-wrapper--icon">
					<FaPaperPlane />
				</span>
			</ShareModalButton>
		</div>
	);
};

ShareVideoButton.propTypes = {
	video: PropTypes.shape({
		_id: PropTypes.string,
	}).isRequired,
	miniPlayer: PropTypes.bool,
};

ShareVideoButton.defaultProps = {
	miniPlayer: false,
};
