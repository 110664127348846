import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import {
	FaCode,
	FaComment,
	FaEnvelope,
	FaLink,
	FaMobileAlt,
	FaUser,
	FaUsers,
} from 'react-icons/fa';
import { FaUserGroup } from 'react-icons/fa6';
import {
	ShareTabEmail,
	ShareTabGroups,
	ShareTabLink,
	ShareTabPost,
	ShareTabSms,
	ShareTabStudioParticipants,
	ShareTabUsers,
	ShareTabEmbed,
	ShareTabUsersFollowersFriends,
} from './Tab';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';
import { ShareTabType } from './Share.helper';
import ReallyPerfectScrollbar from '../ReallyPerfectScrollbar/ReallyPerfectScrollbar';

import './Share.scss';

const projectName = import.meta.env.VITE_PROJECT;

const ShareTabTypeIconMap = {
	[ShareTabType.EMAIL]: <FaEnvelope />,
	[ShareTabType.GROUPS]: <FaUserGroup />,
	[ShareTabType.LINK]: <FaLink />,
	[ShareTabType.POST]: <FaComment />,
	[ShareTabType.SMS]: <FaMobileAlt />,
	[ShareTabType.STUDIO_PARTICIPANTS]: <FaUsers />,
	[ShareTabType.USERS]: <FaUser />,
	[ShareTabType.EMBED]: <FaCode />,
	[ShareTabType.USERS_FOLLOWERS_FRIENDS]: <FaUsers />,
};

const ShareTabTypeComponentMap = {
	[ShareTabType.EMAIL]: ShareTabEmail,
	[ShareTabType.GROUPS]: ShareTabGroups,
	[ShareTabType.LINK]: ShareTabLink,
	[ShareTabType.POST]: ShareTabPost,
	[ShareTabType.SMS]: ShareTabSms,
	[ShareTabType.STUDIO_PARTICIPANTS]: ShareTabStudioParticipants,
	[ShareTabType.USERS]: ShareTabUsers,
	[ShareTabType.EMBED]: ShareTabEmbed,
	[ShareTabType.USERS_FOLLOWERS_FRIENDS]: ShareTabUsersFollowersFriends,
};

export const Share = ({
	alreadySharedUsers,
	channel,
	defaultMessage,
	inputColor,
	onShare,
	openRole,
	post,
	roles,
	shareButtonTranslationKey,
	shareTabTypes,
	showSuccessMessage,
	studio,
	video,
}) => {
	const { t } = useTranslation();
	const [activeMenu, setActiveMenu] = useState(shareTabTypes[0]);
	const [selectedRole, setSelectedRole] = useState(openRole || roles[0]);

	const handleClickMenu = useCallback((tab) => {
		if (shareTabTypes.includes(tab)) setActiveMenu(tab);
	}, [shareTabTypes]);

	useEffect(() => {
		if (!shareTabTypes.includes(activeMenu)) setActiveMenu(shareTabTypes[0]);
		if (activeMenu !== ShareTabType.USERS
			&& selectedRole === ResourceAccessRole.OPERATOR) {
			setSelectedRole(ResourceAccessRole.PARTICIPANT);
		}
	}, [shareTabTypes, activeMenu, selectedRole]);

	const shareDefaultMessage = alreadySharedUsers?.length > 0
		? alreadySharedUsers[alreadySharedUsers.length - 1].message
		: defaultMessage;

	return (
		<div className="Share h-100 d-flex flex-column">
			<div className="nav-line-dark w-100">
				<Nav className="w-100 d-flex nav-line justify-content-around">
					{shareTabTypes.map((shareTabType) => (
						<NavItem key={`share-nav-${shareTabType}`}>
							<NavLink
								className={clsx({ active: activeMenu === shareTabType }, 'd-flex flex-column px-1')}
								onClick={() => handleClickMenu(shareTabType)}
							>
								<Button
									className={clsx({ active: activeMenu === shareTabType }, 'btn-pill d-30 p-0 ')}
									color="neutral-secondary"
									title={t(`Share.Share.${shareTabType}`, { projectName })}
								>
									<span className="btn-wrapper--icon">
										{ShareTabTypeIconMap[shareTabType]}
									</span>
								</Button>
								<small>{t(`Share.Share.${shareTabType}`, { projectName }).toUpperCase()}</small>
							</NavLink>
						</NavItem>
					))}
				</Nav>
			</div>
			<TabContent activeTab={activeMenu} className="TabContent h-100 overflow-hidden mt-2 mb-3">
				{shareTabTypes.map((shareTabType) => {
					const TabComponent = ShareTabTypeComponentMap[shareTabType];
					return (
						<TabPane tabId={shareTabType} className="h-100" key={`share-tab-${shareTabType}`}>
							<ReallyPerfectScrollbar>
								<TabComponent
									alreadySharedUsers={alreadySharedUsers}
									channel={channel}
									defaultMessage={shareDefaultMessage}
									inputColor={inputColor}
									onShare={onShare}
									post={post}
									roles={roles}
									selectedRole={selectedRole}
									setSelectedRole={setSelectedRole}
									shareButtonTranslationKey={shareButtonTranslationKey}
									showSuccessMessage={showSuccessMessage}
									studio={studio}
									video={video}
								/>
							</ReallyPerfectScrollbar>
						</TabPane>
					);
				})}
			</TabContent>
		</div>
	);
};

Share.propTypes = {
	alreadySharedUsers: PropTypes.arrayOf(PropTypes.shape({
		message: PropTypes.string,
	})),
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	defaultMessage: PropTypes.string,
	inputColor: PropTypes.oneOf(['dark', 'light']),
	onShare: PropTypes.func.isRequired,
	openRole: PropTypes.oneOf(Object.values(ResourceAccessRole)),
	post: PropTypes.shape({}),
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	shareButtonTranslationKey: PropTypes.string,
	shareTabTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ShareTabType))).isRequired,
	showSuccessMessage: PropTypes.bool,
	studio: PropTypes.shape({
		_id: PropTypes.string,
	}),
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
};

Share.defaultProps = {
	alreadySharedUsers: [],
	channel: undefined,
	defaultMessage: '',
	inputColor: 'dark',
	openRole: undefined,
	post: undefined,
	shareButtonTranslationKey: 'Share.Buttons.share',
	showSuccessMessage: true,
	studio: undefined,
	video: undefined,
};
