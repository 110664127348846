import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, InputGroup, Row, TabPane } from 'reactstrap';

import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { ShareRoleInput } from '../RoleInput';
import { getShareLink, ShareTabType } from '../Share.helper';
import { AlertTimeout } from '../../Alert/Timeout';

export const ShareTabLink = ({
	channel,
	inputColor,
	post,
	roles,
	selectedRole,
	setSelectedRole,
	studio,
	video,
}) => {
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const { t } = useTranslation();
	const link = getShareLink({ channel, studio, video, role: selectedRole, post });

	const handleCopyLink = useCallback(() => {
		navigator.clipboard.writeText(link)
			.then(() => {
				setSuccess(true);
				setTimeout(() => setSuccess(false), 3000);
			}, () => {
				setError(true);
				setTimeout(() => setError(false), 3000);
			});
	}, [link]);

	return (
		<TabPane tabId="Link">
			<Row className={`g-0 form-${inputColor} h-100 w-100 d-flex justify-content-center align-items-center`}>
				<h4 className="text-center pt-2 pb-2 font-weight-bold mt-3">
					{t('Share.Tab.Link.youCanCopyLinkAndPasteIt')}
				</h4>
				<InputGroup className="mb-3">
					<Input
						className={`text-truncate border-${inputColor} bg-${inputColor}`}
						type="url"
						name="url"
						id="url"
						value={link}
						readOnly
						onClick={handleCopyLink}
					/>
				</InputGroup>
				{success && (
					<AlertTimeout color="success" onTimeout={() => setSuccess(false)} timeoutTime={3000}>
						{t('Share.Tab.Link.linkCopied')}
					</AlertTimeout>
				)}
				{error && (
					<AlertTimeout color="danger" onTimeout={() => setError(false)}>
						{t('Share.Tab.Link.somethingWentWrong')}
					</AlertTimeout>
				)}
				<div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start align-items-sm-center pl-1">
					<ShareRoleInput
						value={selectedRole}
						onChange={(role) => setSelectedRole(role)}
						roles={roles}
						shareTabType={ShareTabType.LINK}
					/>
					<div className="mb-3 d-flex justify-content-between">
						<Button
							color="primary"
							className="btn-pill shadow-none font-weight-bold"
							onClick={handleCopyLink}
						>
							{t('Share.Tab.Link.copyLink')}
						</Button>
					</div>
				</div>
			</Row>
		</TabPane>
	);
};

ShareTabLink.propTypes = {
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	inputColor: PropTypes.oneOf(['dark', 'light']),
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))),
	studio: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	post: PropTypes.shape({}),
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
};

ShareTabLink.defaultProps = {
	channel: undefined,
	inputColor: 'dark',
	roles: [],
	studio: undefined,
	video: undefined,
	post: undefined,
};
