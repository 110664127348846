import PropTypes from 'prop-types';

export const BlankPage = (props) => {
	const { children } = props;

	return (
		<div className="app-wrapper bg-dark content-dark">
			<div className="app-main">
				<div className="app-content">
					<div className="app-content--inner">
						<div className="app-content--inner__wrapper">
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

BlankPage.propTypes = {
	children: PropTypes.node,
};

BlankPage.defaultProps = {
	children: null,
};
