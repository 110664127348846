import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import Select from 'react-select';
import { FaUserFriends } from 'react-icons/fa';

import Hexagon from '../Hexagon/Hexagon';
import { getAvatar } from '../StudioSchedule/Participants/Participants.helper';
import { useFetchUserSuggestions } from '../../api-hooks/search';
import { useProfile } from '../Profile/ProfileContext';

const customDropdownStyles = {
	dropdownIndicator: (baseStyles) => ({
		...baseStyles,
		display: 'none',
	}),
	indicatorSeparator: (baseStyles) => ({
		...baseStyles,
		display: 'none',
	}),
};

const CustomOption = ({ data, innerProps, innerRef }) => {
	const { t } = useTranslation();
	return (
		<div ref={innerRef} {...innerProps} className={`d-flex py-1 ${innerRef ? 'selected' : ''}`}>
			<Hexagon className="d-block d-40 flex-shrink-0 ms-3">
				<img src={getAvatar(data.user.avatar)} className="img-fluid" alt="Avatar" />
			</Hexagon>
			<div className="d-flex justify-content-between w-100 align-items-center mx-3 overflow-hidden">
				<strong className="font-size-sm text-truncate">{(data.user.isFriend || data.user.isOperator) && (<FaUserFriends className="mr-2" />)} {data.label}</strong>
				<div className="d-flex align-items-center ms-3">
					<Badge color={data.user.isConnected ? 'success' : 'second'} className="position-relative badge-circle ml-1" style={{ lineHeight: '15px' }}>
						{t('Share.ItemFriend.online')}
					</Badge>
					<span className="ml-2 content-light-50 font-size-sm text-nowrap">{data.user.isConnected ? t('Share.ItemFriend.online') : t('Share.ItemFriend.offline')}</span>
				</div>
			</div>
		</div>
	);
};

CustomOption.propTypes = {
	data: PropTypes.shape({
		label: PropTypes.string.isRequired,
		user: PropTypes.shape({
			avatar: PropTypes.string,
			isConnected: PropTypes.bool,
			isFriend: PropTypes.bool,
			isOperator: PropTypes.bool,
		}).isRequired,
	}).isRequired,
	innerProps: PropTypes.shape({}).isRequired,
	innerRef: PropTypes.func,
};

CustomOption.defaultProps = {
	innerRef: undefined,
};

// In this component, the suggestions either come from the props or are fetched from the API.
export const SelectUsersMulti = ({
	alreadySelectedUsersIds,
	color,
	isLoading,
	operatorsOnly,
	propSuggestions,
	setPropSuggestionsSearchValue,
	setUsers,
	suggestionsFilter,
	users,
	...props
}) => {
	const [searchValue, setSearchValue] = useState('');
	const { profile } = useProfile();

	const { data: fetchedSuggestions, isLoading: isSuggesting } = useFetchUserSuggestions(
		searchValue, { operatorsOnly },
	);

	const suggestions = propSuggestions ?? fetchedSuggestions;

	// Filter suggestions:
	// - Remove already selected users
	// - Remove current user
	// - Remove users that don't match the prop filter
	const filteredSuggestions = useMemo(() => (suggestions || [])
		.filter((user) => !alreadySelectedUsersIds.includes(user.id))
		.filter((suggestion) => suggestion.id !== profile._id && suggestionsFilter(suggestion))
		.sort((a, b) => {
			// eslint-disable-next-line no-nested-ternary
			a = a.isOperator ? 2 : a.isFriend ? 1 : 0;
			// eslint-disable-next-line no-nested-ternary
			b = b.isOperator ? 2 : b.isFriend ? 1 : 0;
			return b - a;
		})
		.map((suggestion) => ({
			label: suggestion.label,
			user: {
				...suggestion.user,
				isFriend: suggestion.isFriend,
				isOperator: suggestion.isOperator,
			},
			value: suggestion.id,
		})), [suggestions, alreadySelectedUsersIds, profile._id, suggestionsFilter]);

	return 	(
		<Select
			captureMenuScroll={false}
			classNamePrefix={`react-select-${color}`}
			closeMenuOnSelect={!propSuggestions}
			components={{ Option: CustomOption }}
			hideSelectedOptions
			isLoading={isSuggesting || isLoading}
			isMulti
			isSearchable
			noOptionsMessage={() => null}
			onChange={setUsers}
			onInputChange={(value) => (setPropSuggestionsSearchValue
				? setPropSuggestionsSearchValue(value) : setSearchValue(value))}
			options={filteredSuggestions}
			styles={customDropdownStyles}
			value={users}
			{...props}
		/>
	);
};

SelectUsersMulti.propTypes = {
	alreadySelectedUsersIds: PropTypes.arrayOf(PropTypes.string),
	color: PropTypes.oneOf(['dark', 'dark-border', 'light', 'light-border']),
	isLoading: PropTypes.bool,
	operatorsOnly: PropTypes.bool,
	propSuggestions: PropTypes.arrayOf(PropTypes.shape({})),
	setPropSuggestionsSearchValue: PropTypes.func,
	setUsers: PropTypes.func.isRequired,
	suggestionsFilter: PropTypes.func,
	users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SelectUsersMulti.defaultProps = {
	alreadySelectedUsersIds: [],
	color: 'dark',
	isLoading: false,
	operatorsOnly: false,
	propSuggestions: undefined,
	setPropSuggestionsSearchValue: undefined,
	suggestionsFilter: () => true,
};
