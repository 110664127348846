import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { useTalkbackSender, useMediaUser } from '@technomiam/react-video';

import { MediaErrorPopover } from './MediaErrorPopover';

export const LocalMic = ({ className, configId, ...props }) => {
	const { toggleAudio, getIsUserAudioActive, userAudioRequestErrors } = useMediaUser();
	const { recipientUser } = useTalkbackSender();
	const isTalkbackSending = !!recipientUser;
	const userAudioRequestError = userAudioRequestErrors.find((e) => (
		e.configId === configId && e.error
	));
	const userAudioActive = getIsUserAudioActive(configId);

	let color = 'primary';
	if (userAudioRequestError) {
		color = 'neutral-danger';
	} else if (!userAudioActive) {
		color = 'neutral-primary';
	} else if (isTalkbackSending) {
		color = 'white';
	}

	let animatedIconColor = 'primary';
	if (userAudioRequestError) {
		animatedIconColor = 'danger';
	} else if (isTalkbackSending) {
		animatedIconColor = 'white';
	}

	return (
		<>
			<Button
				id="LocalMic"
				tag="span"
				className={clsx('LocalMic btn-animated-icon', `btn-animated-icon--${animatedIconColor} content-${animatedIconColor}`, className)}
				color={color}
				onClick={() => toggleAudio(configId)}
				{...props}
			>
				<span className="btn-wrapper--icon d-inline-block">
					{!userAudioActive
						? <FaMicrophoneSlash size={20} />
						: <FaMicrophone size={20} className={isTalkbackSending ? 'text-danger' : ''} />}
				</span>
			</Button>
			<MediaErrorPopover error={userAudioRequestError?.error} target="LocalMic" type="microphone" />
		</>
	);
};

LocalMic.propTypes = {
	className: PropTypes.string,
	configId: PropTypes.number,
};

LocalMic.defaultProps = {
	className: '',
	configId: 0,
};
