import { useCallback, useEffect, useState } from 'react';
import {
	Alert,
	FormGroup,
	Input,
	ModalBody,
} from 'reactstrap';
import { Form as SchemaForm, Field, FieldError } from 'react-jsonschema-form-validation';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { useModeration } from './Context';
import { useStudio } from '../../Studio/Provider';
import { FormModal } from '../../Form/Modal';
import { FormLabel } from '../../Form/Label';
import FormSubmit from '../../Form/Submit';
import { useAsyncErrorLog } from '../../../lib/hooks';

const getInitialState = (duration) => ({
	days: duration?.days || 0,
	forever: duration.forever,
	hours: duration?.hours || 0,
	minutes: duration ? (duration.minutes || 0) : 5,
});

const formSchema = {
	type: 'object',
	properties: {
		forever: { type: 'boolean' },
		days: { type: 'number', min: 0, max: 90 },
		minutes: { type: 'number', min: 0, max: 59 },
		hours: { type: 'number', min: 0, max: 23 },
	},
};

export const ModerationBanModal = () => {
	const { t } = useTranslation();
	const {
		banModalData,
		banUserFromStudio,
		closeBanModal,
	} = useModeration();
	const { studio } = useStudio();

	const [data, setData] = useState(getInitialState(banModalData.duration));

	const handleChange = useCallback((d) => {
		setData((state) => ({ ...state, ...d }));
	}, []);

	const handleChangeForever = useCallback((e, handleFieldChange) => {
		const { target } = e;
		handleFieldChange({
			target: {
				name: target.name,
				value: target.checked,
			},
		});
	}, []);

	const handleSubmitAsync = useAsyncCallback(async () => {
		let until;

		if (!data.forever) {
			const duration = data.days * 24 * 60 + data.hours * 60 + data.minutes;
			const durationInMilli = duration * 60 * 1000;
			until = new Date(Date.now() + durationInMilli);
		}

		return banUserFromStudio(studio._id, banModalData.user._id, until);
	});

	useAsyncErrorLog(handleSubmitAsync);

	const isSuccess = handleSubmitAsync.status === 'success';

	useEffect(() => {
		if (isSuccess) {
			const timeout = setTimeout(() => { closeBanModal(); }, 2500);
			return () => { clearTimeout(timeout); };
		}
		return undefined;
	}, [closeBanModal, isSuccess]);

	return (
		<FormModal
			isOpen
			toggle={closeBanModal}
		>
			<SchemaForm
				className="h-100 d-flex flex-column"
				data={data}
				onChange={handleChange}
				onSubmit={handleSubmitAsync.execute}
				schema={formSchema}
			>
				<ModalBody>
					<h2 className="text-center mb-3">
						{t('Moderation.BanModal.selectDuration')}
					</h2>
					<div className="d-flex justify-content-center mb-2">
						<FormGroup>
							<FormLabel>
								<Field
									checked={data.forever}
									component={Input}
									name="forever"
									onChange={handleChangeForever}
									type="checkbox"
								/>
								{t('Moderation.BanModal.forever')}
							</FormLabel>
							<FieldError name="forever" />
						</FormGroup>
					</div>
					<div className="d-flex justify-content-center">
						<FormGroup className="mr-2">
							<FormLabel>{t('Moderation.BanModal.days')}</FormLabel>
							<Field
								component={Input}
								disabled={data.forever}
								type="number"
								name="days"
								placeholder={t('Moderation.BanModal.days')}
								value={data.days}
								min="0"
								max="90"
							/>
							<FieldError name="days" />
						</FormGroup>
						<FormGroup className="mr-2">
							<FormLabel>{t('Moderation.BanModal.hours')}</FormLabel>
							<Field
								component={Input}
								disabled={data.forever}
								type="number"
								name="hours"
								placeholder={t('Moderation.BanModal.hours')}
								value={data.hours}
								min="0"
								max="23"
							/>
							<FieldError name="hours" />
						</FormGroup>
						<FormGroup>
							<FormLabel>{t('Moderation.BanModal.minutes')}</FormLabel>
							<Field
								component={Input}
								disabled={data.forever}
								type="number"
								name="minutes"
								placeholder={t('Moderation.BanModal.minutes')}
								value={data.minutes}
								min="0"
								max="59"
							/>
							<FieldError name="minutes" />
						</FormGroup>
					</div>
					{isSuccess && (
						<Alert className="mt-2">{t('Moderation.BanModal.userBanned')}</Alert>
					)}
					{handleSubmitAsync.error && (
						<Alert color="danger">
							{t('An error occurred, please try again in a few minutes.')}
						</Alert>
					)}
					<footer className="d-flex mt-4">
						<FormSubmit
							className="ml-auto mr-auto"
							color="danger"
							disabled={handleSubmitAsync.loading || isSuccess}
							loading={handleSubmitAsync.loading}
						>
							{t('Moderation.BanModal.banUser')} &ldquo;{banModalData.user.nickname}&rdquo;
						</FormSubmit>
					</footer>
				</ModalBody>
			</SchemaForm>
		</FormModal>
	);
};
