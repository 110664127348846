import PropTypes from 'prop-types';
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';
import * as publicStudioApi from '../../api/public/studio';
import * as studioApi from '../../api/studio/studio';
import { usePublicSocket } from '../PublicSocket/Provider';
import { StudioStatus } from '../StudioSchedule/helper';
import { StudioContext, StudioModal, useStudio } from './Context';
import { StudioModalTerminate } from './ModalTerminate';
import { StudioModalLeaveConfirmation } from './ModalLeaveConfirmation';

export { StudioContext, useStudio };

export const StudioProvider = ({ children }) => {
	const [accessStatus, setAccessStatus] = useState();
	const [controlroomActive, setControlroomActive] = useState(false);
	const [studio, setStudio] = useState();
	const [modalOpen, setModalOpen] = useState();
	const [openSurveyModalOnStop, setOpenSurveyModalOnStop] = useState(false);
	const [estimation, setEstimation] = useState(0);
	const [isEstimationBellowLimit, setIsEstimationBellowLimit] = useState(false);
	const [activeContent, setActiveContent] = useState();
	const [onStudioLeaveConfirmation, setOnStudioLeaveConfirmation] = useState();
	const [ownerBalance, setOwnerBalance] = useState({ balance: 0, chipBalance: 0 });

	const { addLiveNotificationHandler } = usePublicSocket();
	const history = useHistory();

	const closeModal = useCallback(() => setModalOpen(undefined), []);

	const resetStudioState = useCallback(() => {
		setAccessStatus(undefined);
		setStudio(undefined);
		setModalOpen(undefined);
		setOpenSurveyModalOnStop(false);
	}, []);

	const setStudioState = useCallback((data) => {
		const { accessStatus: a, activeContent: c, ...s } = data;
		setAccessStatus(a);
		setStudio(s);
		setActiveContent(c);
	}, []);

	const requestStudioByCode = useCallback(async (code, requiredAccess = 'PUBLIC', params) => {
		try {
			const { data } = await publicStudioApi.requestPublicStudioByCode(
				code,
				requiredAccess,
				params,
			);
			setStudioState(data);
			return data;
		} catch (error) {
			setAccessStatus(undefined);
			setStudio(undefined);
			throw error;
		}
	}, [setStudioState]);

	const updateStudio = useCallback((data) => {
		if (!studio || data._id !== studio?._id) return;
		setStudio({ ...studio, ...data });
	}, [studio]);

	const stopActiveStudio = useCallback(async () => {
		if (!studio) throw new Error('No active studio');
		const { data } = await studioApi.stopStudio(studio._id);
		setStudio(data);
		return data;
	}, [studio]);

	const golive = useCallback(async (startTime) => {
		if (!studio) throw new Error('No active studio');
		const { data } = await studioApi.golive(studio._id, startTime);
		setStudio(data);
		return data;
	}, [studio]);

	const stoplive = useCallback(async () => {
		if (!studio) throw new Error('No active studio');
		const { data } = await studioApi.stoplive(studio._id);
		setStudio(data);
		return data;
	}, [studio]);

	const handleEventStudioStatus = useCallback(async (studioUpdate) => {
		setStudio((state) => {
			if (!state || state._id !== studioUpdate._id) return state;
			return {
				...state,
				...studioUpdate,
			};
		});
	}, []);

	useEffect(() => {
		if (!studio) return undefined;
		const removeLiveNotificationHandler = addLiveNotificationHandler((studioUpdate) => {
			handleEventStudioStatus(studioUpdate);
		});
		return () => {
			removeLiveNotificationHandler();
		};
	}, [studio, addLiveNotificationHandler, handleEventStudioStatus]);

	const handleStudioCharge = useCallback((notification) => {
		if (notification.studio === studio?._id) {
			const minutesLeft = Math.ceil(
				(notification.balance + notification.chipBalance) / notification.lastMinuteCharge,
			);
			const totalEstimation =	minutesLeft
				+ (studio ? Math.floor((new Date() - new Date(studio?.readyAt)) / 60000) : 0);

			setEstimation(Math.min(12 * 60, totalEstimation));
			setIsEstimationBellowLimit(minutesLeft <= 5);
			setOwnerBalance({
				balance: notification.balance || 0,
				chipBalance: notification.chipBalance || 0,
			});
		}
	}, [studio]);

	const openStudioLeaveConfirmationModal = useCallback((onStudioLeave) => {
		setModalOpen(StudioModal.LEAVE_CONFIRMATION);
		setOnStudioLeaveConfirmation(onStudioLeave);
	}, []);

	const cleanBlockRef = useRef();

	const handleStudioLeaveConfirmation = useCallback(() => {
		if (cleanBlockRef.current) {
			cleanBlockRef.current();
		}
		if (onStudioLeaveConfirmation) {
			onStudioLeaveConfirmation();
		}
	}, [onStudioLeaveConfirmation]);

	const blockNavigation = useCallback(() => {
		let unblock;

		const cleanBlock = () => {
			unblock();
			cleanBlockRef.current = undefined;
		};

		cleanBlockRef.current = cleanBlock;

		unblock = history.block((location) => {
			openStudioLeaveConfirmationModal(
				() => () => {
					cleanBlock();
					history.push(location.pathname);
				},
			);

			return false;
		});

		return cleanBlock;
	}, [history, openStudioLeaveConfirmationModal]);

	const channel = studio?.owner;
	const isStudioStarted = [
		StudioStatus.RUNNING,
		StudioStatus.PENDING,
	].includes(studio?.status);

	const context = useMemo(() => ({
		accessStatus,
		activeContent,
		channel,
		closeModal,
		controlroomActive,
		estimation,
		golive,
		handleEventStudioStatus,
		handleStudioCharge,
		isEstimationBellowLimit,
		isStudioStarted,
		modalOpen,
		openSurveyModalOnStop,
		ownerBalance,
		requestStudioByCode,
		resetStudioState,
		setAccessStatus,
		setActiveContent,
		setControlroomActive,
		setModalOpen,
		setOpenSurveyModalOnStop,
		setStudio,
		setStudioState,
		stopActiveStudio,
		stoplive,
		studio,
		updateStudio,
		openStudioLeaveConfirmationModal,
		blockNavigation,
	}), [
		accessStatus,
		activeContent,
		channel,
		closeModal,
		controlroomActive,
		estimation,
		golive,
		handleEventStudioStatus,
		handleStudioCharge,
		isEstimationBellowLimit,
		isStudioStarted,
		modalOpen,
		openSurveyModalOnStop,
		ownerBalance,
		requestStudioByCode,
		resetStudioState,
		setAccessStatus,
		setActiveContent,
		setControlroomActive,
		setModalOpen,
		setOpenSurveyModalOnStop,
		setStudio,
		setStudioState,
		stopActiveStudio,
		stoplive,
		studio,
		updateStudio,
		openStudioLeaveConfirmationModal,
		blockNavigation,
	]);

	return (
		<StudioContext.Provider value={context}>
			{children}
			<Suspense fallback={null}>
				{modalOpen === StudioModal.TERMINATE && (
					<StudioModalTerminate
						isOpen
						stopActiveStudio={stopActiveStudio}
						toggle={closeModal}
					/>
				)}
				{modalOpen === StudioModal.LEAVE_CONFIRMATION && (
					<StudioModalLeaveConfirmation
						isOpen
						stopActiveStudio={stopActiveStudio}
						toggle={closeModal}
						onStudioLeaveConfirmation={handleStudioLeaveConfirmation}
					/>
				)}
			</Suspense>
		</StudioContext.Provider>
	);
};

StudioProvider.propTypes = {
	children: PropTypes.node,
};

StudioProvider.defaultProps = {
	children: undefined,
};
