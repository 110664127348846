import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { PREVIOUS_SESSION_KEY } from '../../../lib/Identity';
import { useHandleDeleteDisableSubscription } from '../../../lib/serviceWorkerHook';
import { useAuthentication } from '../../Authentication/Authentication';
import { ButtonPill, ButtonPillOutline } from '../../Button';
import { useLogin } from '../../Login/Provider';
import { ModalScreenHeader } from '../../Modal/Screen/Header';
import { ModalScreen } from '../../Modal/Screen/Screen';
import { useOrganizationSwitch } from '../../OrganizationSwitch/Context';
import { useStudio } from '../../Studio/Context';
import { useCurrentStudio } from '../../Studio/useCurrentStudio';

const project = import.meta.env.VITE_PROJECT === 'beeyou' ? 'Beeyou' : 'Commando Vision';

export const OrganizationExpiredModal = ({ open, closeModal }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const { isCurrentStudioStarted } = useCurrentStudio();
	const { openStudioLeaveConfirmationModal } = useStudio();

	const { logout, restorePreviousSession } = useAuthentication();
	const { openOrganizationSwitchModal } = useOrganizationSwitch();
	const { handleCloseRoutingModal } = useLogin();
	const deleteSubscription = useHandleDeleteDisableSubscription();

	const handleSignOff = useCallback(async () => {
		if (localStorage.getItem(PREVIOUS_SESSION_KEY)) {
			restorePreviousSession();
		} else {
			logout();
		}

		await deleteSubscription();
		handleCloseRoutingModal();
		history.push('/');
		closeModal();
	}, [
		deleteSubscription,
		handleCloseRoutingModal,
		history,
		logout,
		restorePreviousSession,
		closeModal,
	]);

	const handleSignoff = useCallback(() => {
		if (isCurrentStudioStarted) {
			openStudioLeaveConfirmationModal(
				() => () => handleSignOff(),
			);
		} else {
			handleSignOff();
		}
	}, [isCurrentStudioStarted, openStudioLeaveConfirmationModal, handleSignOff]);

	const handleOpenSwitchOrganizationModal = useCallback(() => {
		if (isCurrentStudioStarted) {
			openStudioLeaveConfirmationModal(
				() => () => openOrganizationSwitchModal(),
			);
		} else {
			openOrganizationSwitchModal();
		}
	}, [
		openStudioLeaveConfirmationModal,
		openOrganizationSwitchModal,
		isCurrentStudioStarted,
	]);

	return (
		<ModalScreen
			bodyClassName="bg-gray py-0 m-0 bg-dark"
			header={(
				<ModalScreenHeader
					onClose={closeModal}
					title={t('Organization.ExpiredNotice.organizationSubscription')}
				/>
			)}
			isOpen={open}
			onClose={closeModal}
			size="lg"
		>
			<Container className="py-5 px-3 bg-dark content-dark">
				<Row>
					<Col className="d-flex align-items-center flex-column justify-content-center">
						<FaExclamationTriangle size={60} className="mb-5 text-primary" />
						<p className="text-center">{t('Organization.ExpiredNotice.contactOrganizationFound', { project })}</p>
						<div className="w-100 d-flex justify-content-center mt-4">
							<ButtonPill
								color="primary"
								onClick={handleOpenSwitchOrganizationModal}
								className="mr-3"
							>
								{t('HeaderUserbox.Index.switchOrganization')}
							</ButtonPill>
							<ButtonPillOutline
								color="primary"
								onClick={handleSignoff}
							>
								{t('HeaderUserbox.Index.logout')}
							</ButtonPillOutline>
						</div>
					</Col>
				</Row>
			</Container>
		</ModalScreen>
	);
};

OrganizationExpiredModal.propTypes = {
	open: PropTypes.bool,
	closeModal: PropTypes.func.isRequired,
};

OrganizationExpiredModal.defaultProps = {
	open: false,
};
