import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useConnectSlackChannel } from '../../api-hooks/slack/slack';
import { useQueryParams } from '../../lib/hooks';
import { ButtonPill } from '../Button';

import slackInvite from '../../images/slack-invite.png';
import slackImport from '../../images/slack-import-channel.png';

import './Connect.scss';

export const SlackConnect = () => {
	const { mutate: connectSlackChannel, isSuccess, isLoading, isError } = useConnectSlackChannel();
	const history = useHistory();

	const query = useQueryParams();
	const code = query.get('code');

	useEffect(() => {
		if (code) {
			connectSlackChannel(code);
		}
	}, [code, connectSlackChannel]);

	// eslint-disable-next-line no-nested-ternary
	return !isLoading ? (
		<div>
			{isSuccess && (
				<div className="SlackConnect">
					<h4 className="text-center text-dark">Thank you for connecting you Slack channel with Bee You</h4>
					<p className="text-dark text-left">Please follow these steps to import your Slack channel into Beeyou: </p>
					<ol className="text-dark text-left">
						<li>
							<p>
								Invite the Beeyou Integration bot into your channel, by typing the
								<b>/invite @Beeyou Integration</b>
							</p>
							<img className="SlackTutorialImage" src={slackInvite} alt="inviting a slack bot" />
						</li>
						<li>
							<p>
								In the top right corner, click on your avatar and choose Message,
								then click the <b>Slack</b> tab. <br />
								Find the channel that you want to import into Beeyou,
								and click on the import button.
							</p>
							<img className="SlackTutorialImage" src={slackImport} alt="importing a slack channel" />
						</li>
					</ol>
					<div className="mt-4">
						<ButtonPill
							onClick={() => history.push('/')}
						>
							Go back
						</ButtonPill>
					</div>
				</div>
			)}
			{isError && (
				<div className="SlackConnect">
					<h4 className="text-center text-dark">There was an error while connecting your Slack channel</h4>
					<div className="mt-4">
						<ButtonPill
							onClick={() => history.push('/')}
						>
							Go back
						</ButtonPill>
					</div>
				</div>
			)}
		</div>
	) : null;
};
