import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { NotificationType } from '../../../lib/NotificationType';
import { ADS_LOCAL_STORAGE_KEY } from '../../Studio/Loader';

const NotificationToastVideo = ({
	notification,
	closeToast,
}) => {
	const { t } = useTranslation();
	const {
		data: {
			videoId,
		},
		sender,
		message,
	} = notification;

	const getNotificationTextAndLink = () => ({
		text: t('Notification.Toast.IInviteYouToWatchMyVideo'),
		redirect: `/c/${sender.hashtag}/vod/${videoId}`,
	});
	const { redirect, text, useLink } = getNotificationTextAndLink();

	const skipAd = () => {
		// Used when promoting a viewer to participant. We add the studio _id to the already
		// watched ads (if not already there) to not display the ad to the user.
		const listStudioAds = JSON.parse(localStorage.getItem(ADS_LOCAL_STORAGE_KEY)) || [];
		if (!listStudioAds.some((ad) => ad.studioId === notification.data.studioId)) {
			const newListStudioAds = [
				...listStudioAds,
				{ studioId: notification.data.studioId, date: new Date().toISOString() },
			];
			localStorage.setItem(ADS_LOCAL_STORAGE_KEY, JSON.stringify(newListStudioAds.filter(
				(ad) => new Date(ad.date).getTime() > new Date().getTime() - (48 * 60 * 60 * 1000),
			)));
		}
	};

	const handleAccept = () => {
		if (notification.skipAd) skipAd();
		closeToast();
	};

	return (
		<div
			className={clsx(
				'd-flex justify-content-between',
				{
					'align-items-center': !useLink,
					'flex-column': useLink,
				},
			)}
		>
			<div>{text}</div>
			<div className="d-flex align-items-center justify-content-between flex-shrink-0">
				{!useLink ? (
					<>
						<Button
							color="neutral-success"
							className="d-30 p-0 btn-animated-icon btn-animated-icon--success m-1"
							tag={Link}
							to={redirect}
							onClick={handleAccept}
						>
							<span className="btn-wrapper--icon">
								<IoMdCheckmark
									color="var(--success)"
								/>
							</span>
						</Button>
						<Button
							color="neutral-danger"
							className="d-30 p-0 btn-animated-icon btn-animated-icon--danger m-1"
							onClick={closeToast}
						>
							<span className="btn-wrapper--icon">
								<IoMdClose color="var(--danger)" />
							</span>
						</Button>
					</>
				) : (
					<Link to={redirect} onClick={closeToast} className="ml-auto mt-2">
						{t('Notification.Toast.moreInfo')}
					</Link>
				) }
			</div>
		</div>
	);
};

NotificationToastVideo.propTypes = {
	notification: PropTypes.shape({
		data: PropTypes.shape({
			videoId: PropTypes.string,
		}).isRequired,
		sender: PropTypes.shape({
			avatar: PropTypes.string,
			nickname: PropTypes.string.isRequired,
		}).isRequired,
		skipAd: PropTypes.bool,
		type: PropTypes.oneOf(Object.values(NotificationType)),
		message: PropTypes.string,
	}).isRequired,
	closeToast: PropTypes.func.isRequired,
};

export default NotificationToastVideo;
