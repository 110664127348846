/* eslint-disable react/prop-types */
// @ts-check

/**
 * @typedef {{
 * 	children?: React.ReactNode,
 * }} EmbedPageProps
 */

/** @type {React.FC<EmbedPageProps>} */
export const EmbedPage = ({ children }) => (
	<div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
		{children}
	</div>
);
