// @ts-check

import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Button, ButtonDropdown, ButtonGroup, DropdownMenu, DropdownToggle,
	Nav, NavItem, NavLink,
} from 'reactstrap';
import { FaPowerOff } from 'react-icons/fa';
import { BsBroadcast } from 'react-icons/bs';

import { ButtonPillOutline } from '../Button';
import { CompleteProfileModal } from '../Profile/CompleteModal';
import { useProfile } from '../Profile/ProfileContext';
import { StudioModal } from './Context';
import { useStudio } from './Provider';
import { StudioScheduleModal, useStudioSchedule } from '../StudioSchedule/Context';
import { useCurrentStudio } from './useCurrentStudio';
import { StudioState } from '../StudioSchedule/helper';
import { StudioGoLiveStopModal } from './GoLive/StopModal';
import { StudioGoLiveModal } from './GoLive/Modal';
import { useStudioStatus } from './Status/Context';

import './Button.scss';

export const StudioButtonActive = () => {
	const { t } = useTranslation();
	const { setModalOpen } = useStudio();

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isGoLiveModalOpen, setIsGoLiveModalOpen] = useState(false);
	const [isStopLiveModalOpen, setIsStopLiveModalOpen] = useState(false);
	const { openModal } = useStudioSchedule();

	const {
		isLiveAlmostTerminated: isLiveStopped,
		isLiveRecordingStarted,
		isLiveActive,
		isLiveAboutToStart,
		isLivePlanned,
		isStudioPublic,
		isStudioRunning,
	} = useStudioStatus();

	const showEndButton = !isStudioPublic
		|| isLiveStopped
		|| (isLiveAboutToStart && !isLiveRecordingStarted);
	const showGoLiveButton = isLivePlanned && !isLiveAboutToStart;
	const showStopLiveButton = isLiveActive || isLiveRecordingStarted;

	const handleClick = useCallback(() => {
		if (showEndButton) return setModalOpen(StudioModal.TERMINATE);
		if (showGoLiveButton) return setIsGoLiveModalOpen(true);
		if (showStopLiveButton) return setIsStopLiveModalOpen(true);

		return null;
	}, [setModalOpen, showEndButton, showGoLiveButton, showStopLiveButton]);

	const isGoPublicLiveModalOpen = isLivePlanned && !isLiveAboutToStart && isGoLiveModalOpen;

	return (
		<>
			<ButtonGroup>
				<Button
					color="danger"
					className="flex-shrink-0 font-weight-bold pl-3"
					disabled={!isStudioRunning || (isLiveAboutToStart && !isLiveRecordingStarted)}
					onClick={handleClick}
					size="sm"
				>
					{showEndButton && (
						<>
							<FaPowerOff className="mr-3" size={20} />
							<span>{t('Studio.Button.end')}</span>
						</>
					)}
					{showGoLiveButton && (
						<>
							<BsBroadcast className="mr-sm-3" size={20} />
							<span className="d-none d-sm-block">{t('Studio.Button.goLive')}</span>
						</>
					)}
					{showStopLiveButton && <span className="d-none d-sm-block">{t('Studio.Button.stopLive')}</span>}
				</Button>
				<ButtonDropdown
					isOpen={dropdownOpen}
					toggle={() => setDropdownOpen(!dropdownOpen)}
				>
					<DropdownToggle
						caret
						className="px-1 StudioButtonDropdown"
						color="neutral-danger"
					/>
					<DropdownMenu end className="overflow-hidden p-2 bg-light border border-dark">
						<Nav pills className="nav-neutral-secondary flex-column font-weight-normal">
							<NavItem>
								<NavLink className="font-weight-normal" onClick={() => openModal(StudioScheduleModal.MANAGE, null, null, { defaultTab: StudioState.ONGOING })}>
									{t('Studio.Button.manage')}
								</NavLink>
							</NavItem>
							{!showEndButton && (
								<NavItem>
									<NavLink className="font-weight-normal" onClick={() => setModalOpen(StudioModal.TERMINATE)}>
										{t('Studio.Button.end')}
									</NavLink>
								</NavItem>
							)}
						</Nav>
					</DropdownMenu>
				</ButtonDropdown>
			</ButtonGroup>
			{isGoPublicLiveModalOpen && (
				<StudioGoLiveModal
					isOpen={isGoPublicLiveModalOpen}
					toggle={() => setIsGoLiveModalOpen(false)}
				/>
			)}
			{isStopLiveModalOpen && (
				<StudioGoLiveStopModal
					isOpen={isStopLiveModalOpen}
					toggle={() => setIsStopLiveModalOpen(false)}
				/>
			)}
		</>
	);
};

export const StudioButtonLeave = () => {
	const history = useHistory();
	const { t } = useTranslation();

	const {
		isCurrentStudioStarted,
	} = useCurrentStudio();

	const handleLeaveStudio = useCallback(() => {
		history.push('/');
	}, [history]);

	return isCurrentStudioStarted && (
		<ButtonPillOutline
			color="secondary"
			className="flex-shrink-0 shadow-none w-100"
			onClick={handleLeaveStudio}
			size="sm"
		>
			{t('Studio.Button.exit')}
		</ButtonPillOutline>
	);
};

export const StudioButton = () => {
	const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(false);
	const { profile } = useProfile();

	return (
		<div className="d-flex align-items-center">
			{!profile?.completedRewardClaimed && (
				<CompleteProfileModal
					isOpen={showCompleteProfileModal}
					close={() => setShowCompleteProfileModal(false)}
				/>
			)}
		</div>
	);
};
