import Ajv from 'ajv';
import { t as tStatic } from 'i18next';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Container, FormGroup, Input, Popover, PopoverBody, Row } from 'reactstrap';
import { PublicPathPrefix } from '../../../RoutePath';
import { useFetchOrganizationInvite } from '../../../api-hooks/identity/authentication';
import { useGoogleSignup, useSignup } from '../../../api-hooks/profile/signup';
import { useAsyncErrorLog, useQueryParams } from '../../../lib/hooks';
import { useAuthentication } from '../../Authentication/Authentication';
import { ButtonPill } from '../../Button';
import { FormSchemaForm } from '../../Form/Form';
import { FormLabel } from '../../Form/Label';
import FormSubmit from '../../Form/Submit';
import { LoginStepOrganizationChallenge } from '../../Login/StepOrganizationChallenge';
import { MIN_PASSWORD_LENGTH, passwordLowercaseSchema, passwordNumberSchema, passwordSpecialCharacterSchema, passwordUppercaseSchema } from '../../Login/password.schema';
import { OrganizationVisibility, OrganizationVisibilityLabel } from '../../OrganizationSettings/organizationSettings.schema';
import { useUserPreferences } from '../../UserPreferences/Context';
import {
	AccountType,
	AccountTypeLabel,
	CredentialsStrategy,
	IdentificationField,
	PasswordValidationCriteria,
	SignupFormStep,
	useGetSignupSchema,
} from '../signup.schema';
import { SignupFormCreateAccount } from './CreateAccount';
import { SignupFormCreateOrganization } from './CreateOrganization';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

const ajv = new Ajv({
	allErrors: true,
	v5: true,
	$data: true,
});

ajv.addKeyword('phone', {
	schema: false,
	validate: function validatePhone(data) {
		const isValid = isPossiblePhoneNumber(data);
		if (!isValid) validatePhone.errors = [{ message: tStatic('Input.Verified.phone.invalidNumber'), keyword: 'phone', params: {} }];
		return isValid;
	},
});

export const SHOW_COMPLETE_PROFILE_MODAL_KEY = 'showCompleteProfileModal';

const PatternCriteria = {
	[PasswordValidationCriteria.UPPERCASE_LETTER]: passwordUppercaseSchema.pattern,
	[PasswordValidationCriteria.LOWECASE_LETTER]: passwordLowercaseSchema.pattern,
	[PasswordValidationCriteria.NUMBER]: passwordNumberSchema.pattern,
	[PasswordValidationCriteria.SPECIAL_CHARACTER]: passwordSpecialCharacterSchema.pattern,
};

const clearAccount = (account) => {
	const preparedAccount = { ...account };
	delete preparedAccount.identificationField;

	if (account.identificationField === IdentificationField.EMAIL) {
		delete preparedAccount.phoneNumber;
	} else if (account.identificationField === IdentificationField.PHONE) {
		delete preparedAccount.email;
	}

	return preparedAccount;
};

export const SignupForm = ({
	closeModal,
	stepOptions,
	onChangeStep,
	currentStep,
}) => {
	const { mutate: signup, isLoading, error } = useSignup();
	const {
		mutate: googleSignup,
		error: googleSignupError,
	} = useGoogleSignup();
	const {
		cancelLoginChallengeSelectOrganization,
		isLoggedIn,
		login,
		loginAfterGoogleSignUp,
		loginChallengeSelectOrganization,
	} = useAuthentication();

	const { t } = useTranslation();
	const [nameError, setNameError] = useState(true);
	const [passwordErrors, setPasswordErrors] = useState(Object.values(PasswordValidationCriteria));

	const [showPopover, setShowPopover] = useState(false);

	const { setUserPreferences } = useUserPreferences();
	const query = useQueryParams();
	const { data: organizationInvite } = useFetchOrganizationInvite(query.get('organizationInvite'));

	const [signupData, setSignupData] = useState({
		accountType: isBeeyou ? AccountType.PERSONAL : AccountType.NEW_ORGANIZATION,
		identificationField: null,
		organizationVisibility: OrganizationVisibility.PRIVATE,
		credentialsStrategy: CredentialsStrategy.NEW,
		organizationName: '',
		email: '',
		phoneNumber: '',
	});

	const signupSchema = useGetSignupSchema(signupData);

	const canSignup = useMemo(
		() => !!signupData.nickname && (signupData.email || signupData.phoneNumber)
			&& !!signupData.password,
		[signupData],
	);

	const accountTypeOptions = useMemo(() => (organizationInvite ? [{
		key: AccountType.JOIN_ORGANIZATION,
		label: `${t('Onboarding.Signup.join')} ${organizationInvite.organization.name}`,
	}] : [
		{ key: AccountType.PERSONAL, label: t(AccountTypeLabel[AccountType.PERSONAL]) },
		{ key: AccountType.NEW_ORGANIZATION, label: t(AccountTypeLabel[AccountType.NEW_ORGANIZATION]) },
	]), [organizationInvite, t]);

	const addCriteriaToPasswordError = useCallback((criteria) => {
		setPasswordErrors((errors) => (!errors.includes(criteria) ? [...errors, criteria] : errors));
	}, []);

	const removeCriteriaFromPasswordError = useCallback((criteria) => {
		setPasswordErrors((errors) => errors.filter((e) => e !== criteria));
	}, []);

	const matchPattern = useCallback((value, pattern) => {
		if (!value) {
			return false;
		}

		const regex = new RegExp(pattern);
		return regex.test(value);
	}, []);

	const handleChange = useCallback((data) => {
		setSignupData((prevState) => ({ ...prevState, ...data }));

		if (!data.nickname) {
			setNameError(true);
		} else {
			setNameError(false);
		}

		if (!data.password || data.password?.length < MIN_PASSWORD_LENGTH) {
			addCriteriaToPasswordError(PasswordValidationCriteria.LENGHT);
		} else {
			removeCriteriaFromPasswordError(PasswordValidationCriteria.LENGHT);
		}

		Object.keys(PatternCriteria).forEach((key) => {
			if (!matchPattern(data.password, PatternCriteria[key])) {
				addCriteriaToPasswordError(key);
			} else {
				removeCriteriaFromPasswordError(key);
			}
		});
	}, [addCriteriaToPasswordError, matchPattern, removeCriteriaFromPasswordError]);

	const handleChangeAccountType = useCallback((data) => {
		const { value: accountType } = data.target;

		handleChange({ accountType });

		onChangeStep(
			accountType === AccountType.PERSONAL
				? SignupFormStep.CREATE_ACCOUNT
				: SignupFormStep.CREATE_ORGANIZATION,
		);
	}, [handleChange, onChangeStep]);

	const onLogin = useCallback(async (loginResponse, skipProfileComplete = false) => {
		if (loginResponse.requireOrganizationChallenge) {
			onChangeStep(SignupFormStep.ORGANIZATION_CHALLENGE, loginResponse);
			return;
		}
		if (!skipProfileComplete) {
			setUserPreferences(
				(s) => ({ ...s, [SHOW_COMPLETE_PROFILE_MODAL_KEY]: true }),
			);
		}
		closeModal();
	}, [closeModal, onChangeStep, setUserPreferences]);

	const handleLoginUser = useCallback(async (loginData) => {
		const loginResponse = await login(loginData);
		onLogin(loginResponse);
	}, [login, onLogin]);

	const handleSubmit = useCallback((skipProfileComplete = false) => {
		const preparedData = clearAccount(signupData);
		if (signupData.accountType === AccountType.NEW_ORGANIZATION) {
			preparedData.organization = {
				name: signupData.organizationName,
				visibility: signupData.organizationVisibility,
			};
		}

		signup(preparedData, {
			onSuccess: (data) => {
				const organizationId = (data?.organization && typeof data.organization === 'object')
					? data.organization._id
					: data?.organization;

				handleLoginUser({
					identifier: signupData.identificationField === IdentificationField.EMAIL
						? signupData.email : signupData.phoneNumber,
					password: signupData.password,
					organizationId,
				}, skipProfileComplete);
			},
		});
	}, [signup, signupData, handleLoginUser]);
	useAsyncErrorLog({ error: undefined });

	useEffect(() => {
		if (organizationInvite) {
			setSignupData((prevData) => ({
				...prevData,
				accountType: AccountType.JOIN_ORGANIZATION,
				email: organizationInvite.email,
				phoneNumber: organizationInvite.phoneNumber,
				identificationField: organizationInvite.email
					? IdentificationField.EMAIL
					: IdentificationField.PHONE,
				credentialsStrategy: organizationInvite.existingCredentials
					? CredentialsStrategy.EXISTING
					: CredentialsStrategy.NEW,
				organizationJoinInviteId: organizationInvite._id,
			}));

			onChangeStep(SignupFormStep.CREATE_ACCOUNT);
		}
	}, [onChangeStep, organizationInvite]);

	useEffect(() => {
		if (error?.response?.status === 403) {
			setSignupData((state) => ({ ...state, password: '' }));
		}
	}, [error]);

	const completeButtonLabel = useMemo(() => {
		if (signupData.accountType === AccountType.JOIN_ORGANIZATION) {
			if (signupData.credentialsStrategy === CredentialsStrategy.EXISTING) {
				return 'Onboarding.Signup.joinOrganization';
			}
			return 'Onboarding.Signup.createNewAccount';
		} if (signupData.accountType === AccountType.NEW_ORGANIZATION) {
			return 'Onboarding.Signup.createOrganization';
		}
		return 'Onboarding.Signup.signUp';
	}, [signupData]);

	const handleSubmitStepOrganizationChallenge = useCallback(async (organizationId) => {
		await loginChallengeSelectOrganization({
			organizationId,
			organizationChallengeToken: (
				stepOptions[SignupFormStep.ORGANIZATION_CHALLENGE].organizationChallengeToken
			),
		});
		closeModal();
	}, [loginChallengeSelectOrganization, stepOptions, closeModal]);

	const handleCancelLoginChallengeSelectOrganization = useCallback(() => {
		cancelLoginChallengeSelectOrganization();
	}, [cancelLoginChallengeSelectOrganization]);

	const handleGoogleSubmit = useCallback(async (loginData) => {
		const preparedData = { ...loginData, nickname: signupData.nickname };

		if (signupData.accountType === AccountType.JOIN_ORGANIZATION) {
			preparedData.organizationJoinInviteId = organizationInvite._id;
		} else if (signupData.accountType === AccountType.NEW_ORGANIZATION) {
			preparedData.organization = {
				name: signupData.organizationName,
				visibility: signupData.organizationVisibility,
			};
		}

		googleSignup(preparedData, {
			onSuccess: (tokens) => {
				loginAfterGoogleSignUp(tokens);
				setUserPreferences(
					(s) => (
						{ ...s, [SHOW_COMPLETE_PROFILE_MODAL_KEY]: !tokens?.requireOrganizationChallenge }),
				);
			},
		});
	}, [googleSignup, loginAfterGoogleSignUp, organizationInvite, signupData, setUserPreferences]);

	useEffect(() => {
		if (isLoggedIn) {
			closeModal();
		}
	}, [closeModal, isLoggedIn]);

	const handleSignupTypeStep = useCallback(() => {
		setSignupData((prevState) => (
			{ ...prevState, identificationField: null }));
	}, []);

	return (
		<Container fluid>
			{currentStep === SignupFormStep.ORGANIZATION_CHALLENGE
				&& (
					<div className="pb-5">
						<LoginStepOrganizationChallenge
							availableOptions={stepOptions[SignupFormStep.ORGANIZATION_CHALLENGE].availableOptions}
							onSubmit={handleSubmitStepOrganizationChallenge}
							onBack={handleCancelLoginChallengeSelectOrganization}
						/>
					</div>
				)}
			{currentStep !== SignupFormStep.ORGANIZATION_CHALLENGE
				&& (
					<FormSchemaForm
						className="mt-5 mx-0 form-light"
						data={signupData}
						onChange={handleChange}
						onSubmit={() => handleSubmit(true)}
						schema={signupSchema}
						ajv={ajv}
					>
						{(!signupData.identificationField
						|| signupData.accountType === AccountType.JOIN_ORGANIZATION) && (
							<Row className="mt-3">
								<Col lg="12">
									<FormGroup>
										<FormLabel>
											{t('Onboarding.Signup.accountType')}
										</FormLabel>
										<Field
											component={Input}
											name="accountType"
											type="select"
											value={signupData.accountType || AccountType.PERSONAL}
											disabled={signupData.accountType === AccountType.JOIN_ORGANIZATION}
											onChange={handleChangeAccountType}
										>
											{accountTypeOptions.map(({ key, label }) => (
												<option
													key={`account-type-${key}`}
													value={key}
												>
													{label}
												</option>
											))}
										</Field>
										<FieldError name="accountType" />
										{signupData.accountType === AccountType.NEW_ORGANIZATION && (
											<p className="text-danger mt-2 font-bold">{t('Onboarding.Signup.organizationPriceNotice')}</p>
										)}
									</FormGroup>
								</Col>
							</Row>
						)}

						{currentStep === SignupFormStep.CREATE_ORGANIZATION && (
							<SignupFormCreateOrganization
								signupData={signupData}
								accountTypeOptions={accountTypeOptions}
							/>
						)}
						{!signupData.identificationField
							&& signupData.accountType === AccountType.NEW_ORGANIZATION
							&& currentStep === SignupFormStep.CREATE_ACCOUNT ? (
								<Row className="mt-3">
									<Col lg="12">
										<FormGroup id="OrganizationVisibilityContainer">
											<FormLabel>
												{t('Onboarding.Signup.organizationVisibility')}
												<span
													onMouseEnter={() => setShowPopover(true)}
													onMouseLeave={() => setShowPopover(false)}
													id="InfoIcon"
												>
													<FaInfoCircle
														size={12}
														className="ml-1"
													/>
												</span>
											</FormLabel>
											<Field
												component={Input}
												name="organizationVisibility"
												type="select"
												value={signupData.organizationVisibility || OrganizationVisibility.PRIVATE}
											>
												{Object.keys(OrganizationVisibility).map((key) => (
													<option
														key={`visibility-type-${key}`}
														value={key}
													>
														{t(OrganizationVisibilityLabel[key])}
													</option>
												))}
											</Field>
											<FieldError name="organizationVisibility" />
											<Popover
												placement="bottom"
												isOpen={showPopover}
												target="InfoIcon"
												container="OrganizationVisibilityContainer"
											>
												<PopoverBody className="text-black">
													{t('Onboarding.Signup.organizationVisibilityInfo')}
													<br />
													<br />
													{t('Onboarding.Signup.organizationVisibilityPublic')}
													<br />
													<br />
													{t('Onboarding.Signup.organizationVisibilityPrivate')}
												</PopoverBody>
											</Popover>
										</FormGroup>
									</Col>
								</Row>
							) : null}

						{signupData.accountType === AccountType.PERSONAL
							|| currentStep === SignupFormStep.CREATE_ACCOUNT
							|| signupData.accountType === AccountType.JOIN_ORGANIZATION ? (
								<SignupFormCreateAccount
									signupData={signupData}
									onChange={handleChange}
									nameError={nameError}
									passwordErrors={passwordErrors}
									handleGoogleSubmit={handleGoogleSubmit}
								/>
							) : null}

						{(error?.response?.status === 403) && (
							<Alert color="danger">
								{t('Login.StepLogin.error.badCredentials')}
							</Alert>
						)}
						{(error?.response?.status === 400 || error?.response?.status === 422)
							// eslint-disable-next-line no-mixed-operators
							|| (googleSignupError?.response?.status === 400) && (
								<Alert color="danger">
									{error?.response?.data?.message || googleSignupError?.response?.data?.message}
								</Alert>
							)}

						{(!!error && ![400, 403, 422].includes(error.response.status))
							// eslint-disable-next-line no-mixed-operators
							|| (!!googleSignupError && ![400, 403, 422]
								// eslint-disable-next-line no-mixed-operators
								.includes(googleSignupError.response.status)) && (
								<Alert color="danger">
									{t('Global.error')}
								</Alert>
							)}

						<footer className="d-flex flex-column align-items-center mt-4">
							{[AccountType.PERSONAL,
								AccountType.JOIN_ORGANIZATION].includes(signupData.accountType)
								|| currentStep === SignupFormStep.CREATE_ACCOUNT ? (
									<>
										{signupData.identificationField !== IdentificationField.GOOGLE
										&& (
											<FormSubmit
												loading={isLoading}
												className="flex-grow-0"
												onClick={() => handleSubmit(true)}
												disabled={!canSignup || isLoading}
											>
												{t(completeButtonLabel)}
											</FormSubmit>
										)}

										{signupData.identificationField
										&& signupData.accountType !== AccountType.JOIN_ORGANIZATION
										&& (
											<Button
												color="link"
												onClick={handleSignupTypeStep}
											>
												Go Back
											</Button>
										)}

										{!signupData.identificationField
										&& currentStep === SignupFormStep.CREATE_ACCOUNT
										&& signupData.accountType !== AccountType.PERSONAL && (
											<Button
												color="link"
												onClick={() => onChangeStep(SignupFormStep.CREATE_ORGANIZATION)}
											>
												Go Back
											</Button>
										)}
									</>
								) : (
									<ButtonPill
										className="mt-2"
										color="primary"
										onClick={() => onChangeStep(SignupFormStep.CREATE_ACCOUNT)}
										type="button"
										disabled={!signupData.organizationName}
									>
										{t(
											'Onboarding.Signup.createAccount',
											{ name: currentStep === SignupFormStep.CREATE_ORGANIZATION ? 'Organization' : 'Account' },
										)}
									</ButtonPill>
								)}
							<p className="text-secondary mt-4">
								{t(import.meta.env.VITE_PROJECT === 'beeyou' ? 'Onboarding.Signup.confirmTos' : 'Onboarding.Signup.confirmTosComvision')}
								{' '}<Link target="_blank" to={`${PublicPathPrefix}/legal/terms`}><strong><u>{t('Onboarding.Signup.tos')}</u></strong></Link>
							</p>
						</footer>
					</FormSchemaForm>
				)}

		</Container>
	);
};

SignupForm.propTypes = {
	closeModal: PropTypes.func.isRequired,
	currentStep: PropTypes.oneOf(Object.values(SignupFormStep)).isRequired,
	onChangeStep: PropTypes.func,
	stepOptions: PropTypes.shape({
		[SignupFormStep.CREATE_ACCOUNT]: PropTypes.shape({}),
		[SignupFormStep.CREATE_ORGANIZATION]: PropTypes.shape({}),
		[SignupFormStep.ORGANIZATION_CHALLENGE]: PropTypes.shape({
			availableOptions: PropTypes.arrayOf({}),
			organizationChallengeToken: PropTypes.string,
		}),
	}).isRequired,
};

SignupForm.defaultProps = {
	onChangeStep: undefined,
};
