/* eslint-disable react/prop-types */
// @ts-check

import { Badge, ButtonGroup, Spinner } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaCircle, FaLock } from 'react-icons/fa';

import { StudioGoLiveCountdown } from './GoLive/Countdown';
import { useCurrentStudio } from './useCurrentStudio';
import { Path } from '../../RoutePath';
import { StudioButtonActive, StudioButtonLeave } from './Button';
import { useStudioStatus } from './Status/Context';
import { StudioStatusProvider } from './Status/Provider';

const StudioStatusBadge = () => {
	const { t } = useTranslation();

	const {
		isLiveAlmostTerminated: isLiveStopped,
		isLiveAboutToStart,
		isLiveRecordingStarted,
		isLiveActive,
		isStudioRunning,
		isStudioPending: isStudioStarting,
	} = useStudioStatus();

	if (isLiveAboutToStart && !isLiveRecordingStarted) return (<StudioGoLiveCountdown />);

	if (isLiveRecordingStarted || isLiveActive) {
		return (
			<Badge
				color="danger"
				title={t('Studio.StatusManager.onAir')}
			>
				<FaCircle className="me-2" />{t('Studio.StatusManager.onAir')}
			</Badge>
		);
	}

	if (isLiveStopped && isStudioRunning) {
		return (
			<Badge
				color="secondary"
				title={t('Studio.StatusManager.liveEnded')}
			>
				<FaLock className="me-2" />{t('Studio.StatusManager.liveEnded')}
			</Badge>
		);
	}

	if (isStudioRunning) {
		return (
			<Badge
				color="secondary"
				title={t('Studio.StatusManager.privateMode')}
			>
				<FaLock className="me-2" />{t('Studio.StatusManager.privateMode')}
			</Badge>
		);
	}

	if (isStudioStarting) {
		return (
			<Badge
				color="secondary"
				title={t('Studio.StatusManager.studioIsStarting')}
				className="d-flex flex-row align-items-center"
			>
				<Spinner className="me-2 text-white" size="sm" />{t('Studio.StatusManager.studioIsStarting')}
			</Badge>
		);
	}

	return null;
};

/**
 * @typedef {{
 * 	className?: string,
 * 	controlroomActive?: boolean,
 * }} StudioStatusManagerProps
 */

export const StudioStatusManager = (
	/** @type {StudioStatusManagerProps} */
	{
		className = '',
		controlroomActive = false,
	},
) => {
	const {
		currentStudio,
	} = useCurrentStudio();

	return (
		<Route
			path={[
				Path.STUDIO_HOST,
				Path.STUDIO_PARTICIPANT,
				Path.STUDIO_OPERATOR,
			]}
		>
			<StudioStatusProvider studio={currentStudio}>
				<div className={`d-flex flex-column align-items-stretch ${className}`}>
					<StudioStatusBadge />
					<div className="mt-1">
						<Switch>
							<Route
								path={[
									Path.STUDIO_HOST,
									Path.STUDIO_OPERATOR,
								]}
							>
								{controlroomActive ? (
									<ButtonGroup className="StudioButtonGroup">
										<StudioButtonActive />
									</ButtonGroup>
								) : (
									<StudioButtonLeave />
								)}
							</Route>
							<Route
								path={Path.STUDIO_PARTICIPANT}
							>
								<StudioButtonLeave />
							</Route>
						</Switch>
					</div>
				</div>
			</StudioStatusProvider>
		</Route>
	);
};
