import { useCallback, useState } from 'react';
import { FaCoins, FaEdit, FaEye } from 'react-icons/fa';
import { Col, NavItem, NavLink, Row, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFetchAdSettings } from '../../../../../api-hooks/ads/ads';
import { useFetchAdCampaigns } from '../../../../../api-hooks/ads/campaign';
import { AdCampaignStatus } from '../../../../../lib/AdCampaignStatus';
import { calculateAdCreditCostMultiplier, caluclateAdCreditCost } from '../../../../../lib/ads';
import { ButtonPill } from '../../../../Button';
import { usePoints } from '../../../../Membership/Points';
import { ModalScreenHeaderNav } from '../../../../Modal/Screen/Header';
import { AdvertisingAdCreate } from './Create';

import './List.scss';
import { formatDate } from '../../../../../lib/time';

export const AdvertisingAdList = () => {
	const { t } = useTranslation();
	const { data: adSettings } = useFetchAdSettings();
	const [showCreateForm, setShowCreateForm] = useState(false);
	const [selectedAd, setSelectedAd] = useState();
	const [activeTab, setActiveTab] = useState(AdCampaignStatus.ACTIVE);
	const { data: adCampaigns } = useFetchAdCampaigns(activeTab);

	const { adCreditBalance } = usePoints();

	const handleViewAdCamapign = useCallback((ad) => {
		setSelectedAd(ad);
		setShowCreateForm(true);
	}, []);

	const handleCreateAdCampaign = useCallback(() => {
		setSelectedAd(undefined);
		setShowCreateForm(true);
	}, []);

	return (
		<div className="AdvertisingCampaigns p-2">
			{!showCreateForm && (
				<ModalScreenHeaderNav className="mb-4">
					<NavItem className="mr-3">
						<NavLink
							title="Active"
							onClick={() => setActiveTab(AdCampaignStatus.ACTIVE)}
							active={activeTab === AdCampaignStatus.ACTIVE}
						>
							<span>{t('AdCampaings.Ad.active')}</span>
							<div className="divider" />
						</NavLink>
					</NavItem>
					<NavItem className="mr-3">
						<NavLink
							title="Completed"
							onClick={() => setActiveTab(AdCampaignStatus.COMPLETED)}
							active={activeTab === AdCampaignStatus.COMPLETED}
						>
							<span>{t('AdCampaings.Ad.completed')}</span>
							<div className="divider" />
						</NavLink>
					</NavItem>
					<NavItem className="mr-3">
						<NavLink
							title="Draft"
							onClick={() => setActiveTab(AdCampaignStatus.DRAFT)}
							active={activeTab === AdCampaignStatus.DRAFT}
						>
							<span>{t('AdCampaings.Ad.draft')}</span>
							<div className="divider" />
						</NavLink>
					</NavItem>
				</ModalScreenHeaderNav>
			)}
			<div className="mx-3 mb-3">
				<FaCoins className="mr-2 text-warning" />
				{t('AdCampaings.Ad.youHave')} {adCreditBalance} {t('AdCampaings.Ad.adCredits')}
			</div>
			{!showCreateForm ? (
				<div>
					{!adCampaigns?.length ? (
						<Row className="h-100 d-flex">
							<Col className="h-100">
								<p className="pl-2 mb-4">{t('AdCampaings.Ad.youDontHaveAnyAds')}</p>
								<ButtonPill
									className="CookiesApproval_Icon_Button ml-2 mb-1 flex-shrink-0"
									color="primary"
									title="Accept All"
									onClick={handleCreateAdCampaign}
								>
									{t('AdCampaings.Ad.createAdCampaign')}
								</ButtonPill>
							</Col>
						</Row>
					) : (
						<div>
							<Table className="TransactionList_table">
								<thead className="text-secondary">
									<tr>
										<th className="text-secondary">{t('AdCampaings.Ad.campaignName')}</th>
										<th className="text-secondary">{t('AdCampaings.Ad.startDate')}</th>
										<th className="text-secondary">{t('AdCampaings.Ad.endDate')}</th>
										<th className="text-secondary">{t('AdCampaings.Ad.currentTotalCost')}</th>
										<th className="text-secondary">{t('AdCampaings.Ad.ongoingAdCreditsLeft')}</th>
										<th className="text-secondary">{t('AdCampaings.Ad.actions')}</th>
									</tr>
								</thead>
								<tbody>
									{adCampaigns.map((adCampaign) => (
										<tr key={`ad-${adCampaign._id}`}>
											<td className="text-secondary">{adCampaign.name}</td>
											<td className="text-secondary">{formatDate(adCampaign.startDate, t)}</td>
											<td className="text-secondary">{formatDate(adCampaign.endDate, t)}</td>
											<td className="text-secondary">
												{adSettings ? caluclateAdCreditCost(
													adCampaign.ads,
													adCampaign.viewTarget,
													calculateAdCreditCostMultiplier(adCampaign, adSettings.maxPriceIncrease),
													adSettings.adCreditToChips,
												) : '-'}
											</td>
											<td className="text-secondary">{adCreditBalance}</td>
											<td className="text-secondary">
												{adCampaign.isDraft ? (
													<FaEdit
														className="text-primary cursor-pointer"
														onClick={() => handleViewAdCamapign(adCampaign)}
													/>
												) : (
													<FaEye
														className="text-primary cursor-pointer"
														onClick={() => handleViewAdCamapign(adCampaign)}
													/>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
							<ButtonPill
								className="ml-2 mb-1 flex-shrink-0"
								color="primary"
								title="Create Ad Campaign"
								onClick={handleCreateAdCampaign}
							>
								{t('AdCampaings.Ad.createAdCampaign')}
							</ButtonPill>
						</div>
					)}
				</div>
			) : (
				<AdvertisingAdCreate
					setShowCreateForm={setShowCreateForm}
					selectedAd={selectedAd}
				/>
			)}
		</div>
	);
};
