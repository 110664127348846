import { useEffect, useMemo } from 'react';
import { Col } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useUserPreferences } from '../UserPreferences/Context';
import { useUserSettings, UserSettingsModal } from '../UserSettings/Context';
import { getFileUrl } from '../../lib/file';
import { getLink, Path } from '../../RoutePath';
import { MediaManager } from '../UserMedia/Manager/Manager';

const ALWAYS_DISPLAY_MEDIA_KEY = 'alwaysDisplayMediaManagerScreen';
const IS_MEDIA_CONFIGURED_KEY = 'isMediaConfigured';

export const useMediaManagerPreference = () => {
	const userPreferences = useUserPreferences();

	useEffect(() => {
		if (userPreferences.userPreferences[ALWAYS_DISPLAY_MEDIA_KEY] === undefined) {
			userPreferences.setUserPreferences((s) => ({ ...s, [ALWAYS_DISPLAY_MEDIA_KEY]: false }));
		}
	}, [userPreferences]);

	const alwaysDisplayMediaChecked = userPreferences.getUserPreference(ALWAYS_DISPLAY_MEDIA_KEY);
	const isMediaConfigured = IS_MEDIA_CONFIGURED_KEY in userPreferences.userPreferences;

	return useMemo(() => ({
		alwaysDisplayMediaChecked,
		isMediaConfigured,
		toggleAlwaysDisplayMediaManagerScreen: () => userPreferences.setUserPreferences(
			(s) => ({ ...s, [ALWAYS_DISPLAY_MEDIA_KEY]: !s[ALWAYS_DISPLAY_MEDIA_KEY] }),
		),
		saveUserConfiguredMedia: () => userPreferences.setUserPreferences(
			(s) => ({ ...s, [IS_MEDIA_CONFIGURED_KEY]: true }),
		),
	}), [
		alwaysDisplayMediaChecked,
		isMediaConfigured,
		userPreferences,
	]);
};

export const MediaManagerWrapper = ({ children }) => {
	const { t } = useTranslation();
	const { openOverModal, closeOverModal } = useUserSettings();
	const {
		alwaysDisplayMediaChecked,
		toggleAlwaysDisplayMediaManagerScreen,
	} = useMediaManagerPreference();

	const handleOpenModal = (callback) => {
		const call = (asset) => {
			closeOverModal();
			const assetUrl = getFileUrl({ name: asset.filename });
			return callback(assetUrl);
		};
		openOverModal(UserSettingsModal.KEY_LIBRARY, undefined, undefined, call);
	};

	return (
		<section className="MediaManagerWrapper d-flex flex-column">
			<MediaManager
				isInStudioPreLoading
				isAlwaysDisplayMediaChecked={alwaysDisplayMediaChecked}
				onKeySelectBackground={handleOpenModal}
				toggleAlwaysDisplayMediaManagerScreen={toggleAlwaysDisplayMediaManagerScreen}
				userPreferenceKey={ALWAYS_DISPLAY_MEDIA_KEY}
			/>
			<Col className="MediaManagerFormSaveModal">
				<p>{t('MediaManager.notWorking')} <a href={getLink(Path.HELP_ANCHOR, { anchor: 'troubleshooting' })} target="_blank" rel="noopener noreferrer">{t('MediaManager.troubleshootingGuide')}</a>.</p>
			</Col>
			{children && (
				<div className="MediaManagerWrapperChildren">
					{children}
				</div>
			)}
		</section>
	);
};

MediaManagerWrapper.propTypes = {
	children: PropTypes.node,
};

MediaManagerWrapper.defaultProps = {
	children: undefined,
};

export default MediaManagerWrapper;
