import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FaHeart } from 'react-icons/fa';
import { MenuCollapse } from '../MenuCollapse/MenuCollapse';
import { useAuthentication } from '../../../components/Authentication/Authentication';
import { useMenu } from '../../../components/Drawer/Context';

export const SidebarMenuFollowing = ({ isCurrentLocationHome }) => {
	const { t } = useTranslation();
	const { user } = useAuthentication();
	const { setActiveTab, toggleMenu } = useMenu();

	const handleOpenMenu = useCallback((tab) => {
		toggleMenu();
		setActiveTab(tab);
	}, [setActiveTab, toggleMenu]);

	const followMenuContent = [
		user && ({
			label: t('SidebarMenu.Index.followers'),
			to: '',
			toggleSidebarOnClick: !isCurrentLocationHome,
			onClick: () => handleOpenMenu('Followers'),
		}),
		user && ({
			label: t('SidebarMenu.Index.friends'),
			to: '',
			toggleSidebarOnClick: false,
			onClick: () => handleOpenMenu('Friends'),
		}),
	];

	const followingMenu = {
		label: t('SidebarMenu.Index.following'),
		icon: <FaHeart className="icon" />,
	};

	return (
		<MenuCollapse parentItem={followingMenu} childItems={followMenuContent} />
	);
};

SidebarMenuFollowing.propTypes = {
	isCurrentLocationHome: PropTypes.bool,
};

SidebarMenuFollowing.defaultProps = {
	isCurrentLocationHome: false,
};
