// @ts-check

import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { ViewForbidden } from '../../views/Forbidden/Forbidden';
import { useAuthentication } from './Authentication';

/**
 * @typedef {{
 * 	component: React.ComponentType,
 * }} RouteIfAuthenticatedProps
 */

const RouteIfAuthenticated = (
	/** @type {RouteIfAuthenticatedProps} */
	{
		component: Component,
		...routeProps
	},
) => {
	const authentication = useAuthentication();

	const render = (/** @type {any} */props) => {
		// wait for first getUser (comment outdated because first getUser is now sync)
		if (authentication.isPending) return null;
		if (!authentication.isLoggedIn) {
			return (
				<ViewForbidden />
			);
		}
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} />;
	};

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Route {...routeProps} render={render} />;
};

RouteIfAuthenticated.propTypes = {
	component: PropTypes.elementType.isRequired,
};

export default RouteIfAuthenticated;
