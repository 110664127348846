import PropTypes from 'prop-types';
import { FaShare } from 'react-icons/fa';

import { Translate } from '../../../i18n';
import { ButtonPill } from '../../Button';
import { useShareModal } from './Context';
import { ShareAboutType, ShareTabType } from '../Share.helper';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';

export const ShareModalButton = ({
	buttonComponent: ButtonComponent,
	channel,
	children,
	className,
	content,
	contentType,
	defaultShareMessage,
	onClick,
	roles,
	studio,
	tabs,
	...props
}) => {
	const { toggleShareModal } = useShareModal();

	return (
		<ButtonComponent
			className={`${className || 'shadow-none bg-light'}`}
			color="neutral-secondary"
			onClick={onClick || (() => toggleShareModal({
				channel,
				content,
				contentType,
				defaultShareMessage,
				roles,
				studio,
				tabs,
			}))}
			type="button"
			{...props}
		>
			{children}
		</ButtonComponent>
	);
};

ShareModalButton.propTypes = {
	buttonComponent: PropTypes.elementType,
	channel: PropTypes.shape({
		hashtag: PropTypes.string,
	}),
	children: PropTypes.node,
	className: PropTypes.string,
	content: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	contentType: PropTypes.oneOf(Object.values(ShareAboutType)),
	defaultShareMessage: PropTypes.string,
	onClick: PropTypes.func,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ResourceAccessRole))),
	studio: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	tabs: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ShareTabType))),
};

ShareModalButton.defaultProps = {
	buttonComponent: ButtonPill,
	channel: undefined,
	children: (
		<>
			<span className="btn-wrapper--icon"><FaShare /></span>
			<span className="btn-wrapper--label d-none-fullscreen-md">
				<Translate k="Share.Button.share" />
			</span>
		</>
	),
	className: '',
	content: undefined,
	contentType: undefined,
	defaultShareMessage: '',
	onClick: undefined,
	roles: undefined,
	studio: undefined,
	tabs: undefined,
};
