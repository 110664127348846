import PropTypes from 'prop-types';
import { useCallback, useId, useState, useRef } from 'react';
import { Alert, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FaVideo } from 'react-icons/fa';

import { useTranslation } from 'react-i18next';
import { UserSettingsModal, useUserSettings } from '../UserSettings/Context';
import { useProfile } from '../Profile/ProfileContext';
import { useUploadVideo } from '../../api-hooks/channel/videos';
import { TooltipTimeout } from '../Tooltip/Timeout';
import { AssetUploadMode, isFileAcceptable } from './Input';

export const properties = {
	filename: { type: 'string' },
};

export const schema = {
	type: 'object',
	additionalProperties: false,
	properties,
	required: [],
};

const MIN_AD_DURATION = 7;
const MAX_AD_DURATION = 30;

export const VideoInputType = {
	AD: 'AD',
	NORMAL: 'NORMAL',
};

export const VideoInput = ({
	setUploadedVideo,
	setVideoUploadProgress,
	videoInputType,
}) => {
	const [videoDropdownOpen, setVideoDropdownOpen] = useState(false);
	const [videoIncorrectTypeError, setVideoIncorrectTypeError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const { t } = useTranslation();

	const { mutate: uploadVideo } = useUploadVideo();

	const { profile } = useProfile();
	const { openModal, closeModal } = useUserSettings();

	const videoInput = useRef(null);

	const id = useId();
	const videoId = `VideoInput-video${id.replaceAll(':', '__')}`;

	const onVideoUploadProgress = useCallback((progress) => {
		setVideoUploadProgress(progress);
	}, [setVideoUploadProgress]);

	const handleVideoUploaded = useCallback((video) => {
		if (video) {
			setUploadedVideo({
				video: video._id,
				filename: video.filename,
				duration: video.duration,
			});
		}
	}, [setUploadedVideo]);

	const handleBrowseVideos = useCallback(() => {
		videoInput.current.click();
	}, [videoInput]);

	const handleUploadVideo = useCallback((videoData, duration) => {
		uploadVideo({
			channelId: profile._id,
			videoFile: videoData,
			onUploadProgress: onVideoUploadProgress,
		}, {
			onSuccess: async (data) => {
				handleVideoUploaded({ ...data, duration });
				setVideoUploadProgress(undefined);
			},
		});
	}, [
		handleVideoUploaded,
		onVideoUploadProgress,
		profile,
		setVideoUploadProgress,
		uploadVideo,
	]);

	const handleVideoChange = useCallback(async (event) => {
		setErrorMessage('');
		setVideoIncorrectTypeError(false);
		const files = Array.from(event.target.files);

		if (!files?.length) {
			return;
		}

		const file = files[0];

		if (file) {
			const video = document.createElement('video');
			video.src = URL.createObjectURL(file);

			video.onloadedmetadata = () => {
				const { duration } = video;

				URL.revokeObjectURL(video.src);

				if (videoInputType === VideoInputType.AD && Math.floor(duration) < MIN_AD_DURATION) {
					setErrorMessage(t('Media.VideInput.adMinLenghtError', { minDuration: MIN_AD_DURATION }));
					return;
				}

				if (videoInputType === VideoInputType.AD && Math.floor(duration) > MAX_AD_DURATION) {
					setErrorMessage(t('Media.VideInput.adMaxLenghtError', { maxDuration: MAX_AD_DURATION }));
					return;
				}

				if (!isFileAcceptable(file, event.target.accept)) {
					setVideoIncorrectTypeError(true);
					return;
				}

				const label = file.name.replace(/\.[^/.]+$/, '');
				const videoData = {
					blob: file,
					label,
					filename: URL.createObjectURL(file),
				};

				handleUploadVideo(videoData, duration);
			};
		}
	}, [handleUploadVideo, t, videoInputType]);

	const handleOpenSelectAssetModal = useCallback((callback, modalType) => {
		const call = (asset) => {
			if (videoInputType === VideoInputType.AD
					&& Math.floor(asset?.duration) < MIN_AD_DURATION
			) {
				setErrorMessage(t('Media.VideInput.adMinLenghtError', { minDuration: MIN_AD_DURATION }));
				closeModal();
				return null;
			}

			if (videoInputType === VideoInputType.AD
					&& Math.floor(asset?.duration) > MAX_AD_DURATION
			) {
				setErrorMessage(t('Media.VideInput.adMaxLenghtError', { maxDuration: MAX_AD_DURATION }));
				closeModal();
				return null;
			}

			closeModal();
			return callback(asset);
		};

		openModal(modalType, undefined, undefined, (asset) => call(asset));
	}, [closeModal, openModal, videoInputType, t]);

	return (
		<>
			<div className="d-flex justify-content-start mt-2 align-items-center">
				<Dropdown
					isOpen={videoDropdownOpen}
					toggle={() => setVideoDropdownOpen(!videoDropdownOpen)}
				>
					<DropdownToggle
						className="bg-transparent shadow-none border-0 text-white m-0 p-1 rounded"
					>
						<Button
							className="font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-30 btn-transition-none"
							color="neutral-info"
							title={t('Media.VideoInput.uploadVideo')}
							type="button"
							id={videoId}
						>
							<FaVideo />
						</Button>
					</DropdownToggle>
					{videoDropdownOpen && (
						<DropdownMenu>
							<DropdownItem onClick={() => handleOpenSelectAssetModal(
								handleVideoUploaded,
								UserSettingsModal.SELECT_VIDEOS,
							)}
							>
								{t(AssetUploadMode.LIBRARY)}
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem onClick={handleBrowseVideos}>
								{t(AssetUploadMode.UPLOAD)}
							</DropdownItem>
						</DropdownMenu>
					)}
				</Dropdown>
			</div>
			<div className="d-flex">
				<input
					className="d-none"
					accept="video/mp4, .mp4, video/mpeg, .mpeg, video/quicktime, .mov, video/webm, .webm"
					type="file"
					onChange={handleVideoChange}
					ref={videoInput}
				/>
				{!!errorMessage && (
					<Alert color="danger">{errorMessage}</Alert>
				)}
			</div>
			<TooltipTimeout
				onTimeout={() => setVideoIncorrectTypeError(false)}
				isOpen={videoIncorrectTypeError}
				placement="top"
				popperClassName="tooltip-danger"
				target={videoId}
			>
				{t('Media.VideoInput.pleaseUploadVideoInTheseFormats')}
			</TooltipTimeout>
		</>
	);
};

VideoInput.propTypes = {
	setUploadedVideo: PropTypes.func,
	setVideoUploadProgress: PropTypes.func,
	videoInputType: PropTypes.oneOf(Object.values(VideoInputType)),
};

VideoInput.defaultProps = {
	setUploadedVideo: undefined,
	setVideoUploadProgress: undefined,
	videoInputType: VideoInputType.NORMAL,
};
