import { useAd } from './Context';

export const AdRewardEstimator = () => {
	const {
		isAdPlaying,
		estimatedCurrentAdReward,
	} = useAd();

	return isAdPlaying && estimatedCurrentAdReward > 0 && (
		<span className="ms-2">
			(+{estimatedCurrentAdReward} Ch)
		</span>
	);
};
