import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Header } from '../../layout-components';
import { useScreenMode, ModeScreenLayout } from '../../components/Studio/ScreenMode/Provider';

const NoSidebar = ({
	children,
	isStudioRoute,
	noSearch,
}) => {
	const { currentModeScreen } = useScreenMode();

	return (
		<div className="app-wrapper bg-darker content-darker">
			<div className={clsx('app-main',
				{ 'overflow-hidden': currentModeScreen === ModeScreenLayout.FULLSCREEN })}
			>
				<Header search={!noSearch} noSideBar isStudioRoute={isStudioRoute} />
				<div className="app-content">
					<div className="app-content--inner">
						<div className="app-content--inner__wrapper">{children}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

NoSidebar.propTypes = {
	children: PropTypes.node,
	isStudioRoute: PropTypes.bool,
	noSearch: PropTypes.bool,
};

NoSidebar.defaultProps = {
	children: null,
	isStudioRoute: false,
	noSearch: false,
};

export default NoSidebar;
