/* eslint-disable max-len */
import { Provider as RollbarProvider } from '@rollbar/react';
import { Fragment, lazy, useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import 'react-jsonschema-form-validation/dist/react-jsonschema-form-validation.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import queryClient from './api-hooks/utils/query-client';

import { AdProvider } from './components/Ad/Provider';
import { AuthenticationProvider } from './components/Authentication/Authentication';
import { BrowserCompatibility } from './components/BrowserCompatibility/BrowserCompatibility';
import { GuestHandRaisingProvider } from './components/Channel/Guest/HandRaisingProvider';
import { ModerationProvider } from './components/Channel/Moderation/Provider';
import { CookiesApproval } from './components/Cookies/Approval';
import { CookieProvider } from './components/Cookies/Provider';
import MenuProvider from './components/Drawer/MenuProvider';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary ';
import { GlobalSocketWrapper } from './components/GlobalSocketWrapper';
import LoginProvider from './components/Login/Provider';
import { PointsProvider } from './components/Membership/Points';
import NotificationManager from './components/Notification/Manager';
import { ProfileProvider } from './components/Profile/ProfileProvider';
import ScrollToTop from './components/ScrollToTop';
import { ActiveStudioProvider } from './components/Studio/Active/Provider';
import { StudioProvider } from './components/Studio/Provider';
import { ScreenModeProvider } from './components/Studio/ScreenMode/Provider';
import { ScreenThemeProvider } from './components/Studio/ScreenTheme/Provider';
import { StudioScheduleProvider } from './components/StudioSchedule/Provider';
import { StudioSettingsProvider } from './components/StudioSettings/Provider';
import { StudioSummaryProvider } from './components/StudioSummary/Provider';
import { SurveyProviderWrapper } from './components/Survey/Providers/Wrapper';
import { UserPreferencesProvider } from './components/UserPreferences/Provider';
import { UserSettingsProvider } from './components/UserSettings/Provider';
import { VoteProvider } from './components/Vote/Provider';
import { SidebarProvider } from './layout-components/Sidebar/Provider';
import { AppsTabProvider } from './views/Controlroom/Apps/Provider';

import Routes from './Routes';

// eslint-disable-next-line import/no-unresolved
import './assets/base.@@PROJECT.scss';
import { ContactsModal } from './components/Contacts/Modal';
import { ContactsModalProvider } from './components/Contacts/Provider';
import { OrganizationSettingsProvider } from './components/OrganizationSettings/Provider';
import { DisplayProvider } from './components/Profile/Preferences/Settings/DisplayProvider';
import { PublicSocketProvider } from './components/PublicSocket/Provider';
import { PwaModal } from './components/Pwa/Modal';
import { PwaProvider } from './components/Pwa/Provider';
import { SoundProvider } from './components/Sound/Provider';
import { WebPushModal } from './components/WebPush/Modal';
import { WebPushProvider } from './components/WebPush/Provider';
import { rollbar } from './lib/rollbar';
import { useInternetStatus } from './lib/useInternetStatus';
import { ShareModalProvider } from './components/Share/Modal/Provider';
import { OrganizationSwitchProvider } from './components/OrganizationSwitch/Provider';
import { ChannelWatchProvider } from './components/Channel/Watch/Provider';
import { HeaderSearchProvider } from './layout-components/Header/SearchProvider';

const FriendsMenuDrawer = lazy(() => import('./components/FriendsMenu/Drawer'));

const { VITE_APP_DEBUG_EASY, VITE_GOOGLE_CLIENT_ID } = import.meta.env;

const ErrorBoundaryDebug = VITE_APP_DEBUG_EASY !== 'true' ? ErrorBoundary : Fragment;

const App = () => {
	const isOnline = useInternetStatus();

	useEffect(() => {
		if (!isOnline) {
			window.location = '/offline.html';
		}
	}, [isOnline]);

	return (

		<RollbarProvider instance={rollbar}>
			<ErrorBoundaryDebug>
				<GoogleOAuthProvider clientId={VITE_GOOGLE_CLIENT_ID}>
					<PwaProvider>
						<SidebarProvider>
							<HeaderSearchProvider>
								<QueryClientProvider client={queryClient}>
									<BrowserCompatibility />
									<SoundProvider>
										<CookieProvider>
											<ScreenThemeProvider>
												<ScreenModeProvider>
													<PwaModal />
													<AuthenticationProvider>
														<DisplayProvider>
															<UserPreferencesProvider>
																<PublicSocketProvider>
																	<WebPushProvider>
																		<WebPushModal />
																		<ContactsModalProvider>
																			<ContactsModal />
																			<ProfileProvider>
																				<AdProvider>
																					<PointsProvider>
																						<Router>
																							<CookiesApproval />
																							<LoginProvider>
																								<ActiveStudioProvider>
																									<StudioProvider>
																										<ModerationProvider>
																											<SurveyProviderWrapper>
																												<VoteProvider>
																													<OrganizationSwitchProvider>
																														<UserSettingsProvider>
																															<ShareModalProvider>
																																<OrganizationSettingsProvider>
																																	<GlobalSocketWrapper>
																																		<StudioSettingsProvider>
																																			<StudioSummaryProvider>
																																				<StudioScheduleProvider>
																																					<GuestHandRaisingProvider>
																																						<MenuProvider
																																							MenuComponent={FriendsMenuDrawer}
																																							direction="right"
																																							width="400px"
																																						>
																																							<AppsTabProvider>
																																								<ChannelWatchProvider>
																																									<ScrollToTop>
																																										<Routes />
																																									</ScrollToTop>
																																								</ChannelWatchProvider>
																																							</AppsTabProvider>
																																							<NotificationManager />
																																						</MenuProvider>
																																					</GuestHandRaisingProvider>
																																				</StudioScheduleProvider>
																																			</StudioSummaryProvider>
																																		</StudioSettingsProvider>
																																	</GlobalSocketWrapper>
																																</OrganizationSettingsProvider>
																															</ShareModalProvider>
																														</UserSettingsProvider>
																													</OrganizationSwitchProvider>
																												</VoteProvider>
																											</SurveyProviderWrapper>
																										</ModerationProvider>
																									</StudioProvider>
																								</ActiveStudioProvider>
																							</LoginProvider>
																						</Router>
																					</PointsProvider>
																				</AdProvider>
																			</ProfileProvider>
																		</ContactsModalProvider>
																	</WebPushProvider>
																</PublicSocketProvider>
															</UserPreferencesProvider>
														</DisplayProvider>
													</AuthenticationProvider>
												</ScreenModeProvider>
											</ScreenThemeProvider>
										</CookieProvider>
									</SoundProvider>
								</QueryClientProvider>
							</HeaderSearchProvider>
						</SidebarProvider>
					</PwaProvider>
				</GoogleOAuthProvider>
			</ErrorBoundaryDebug>
		</RollbarProvider>
	);
};

export default App;
