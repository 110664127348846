import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row } from 'reactstrap';
import { Form } from 'react-jsonschema-form-validation';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { ButtonPill } from '../../../../Button';
import { AdCycleDuration, AdLanguage, AdSharedProps, schema } from './createAds.schema';
import { AdvertisingCampaignsCreate } from './Campaigns/Create/Create';
import FormSubmit from '../../../../Form/Submit';
import { AdvertisingAdManagmentForm } from './Managment/Form';
import { useCreateAdCampaign, useCreateDraftAdCampaign } from '../../../../../api-hooks/ads/campaign';
import { AdvertisingEstimation } from './Estimation/Estimation';
import { Stepper } from '../../../../Stepper/Stepper';
import { ConfirmationModal } from '../../../../../layout-components/ConfirmationModal';
import { addDays } from '../../../../../lib/dates';
import { useProfile } from '../../../../Profile/ProfileContext';
import { GRAPH_GLOBALS_QUERY_KEYS } from '../../../../../api-hooks/graph/globals';

const getInitialAdCampaign = (adCampaign) => ({
	_id: adCampaign?._id || undefined,
	name: adCampaign?.name || '',
	viewTarget: adCampaign?.viewTarget.toString() || '1000',
	spendingLimit: adCampaign?.spendingLimit?.toString() || '1000',
	maxViewsPerCycle: adCampaign?.maxViewsPerCycle?.toString() || '100',
	cycleDuration: adCampaign?.cycleDuration?.toString() || AdCycleDuration.DAILY,
	dailyViewRestriction: adCampaign?.dailyViewRestriction.toString() || '500',
	startDate: adCampaign?.startDate || new Date().toISOString(),
	endDate: adCampaign?.endDate || addDays(new Date(), 7).toISOString(),
	timeSlots: adCampaign?.timeSlots || [AdSharedProps.ANY],
	genders: adCampaign?.genders || [AdSharedProps.ANY],
	ageGroups: adCampaign?.ageGroups || [AdSharedProps.ANY],
	interests: adCampaign?.interests || [AdSharedProps.ANY],
	countries: adCampaign?.countries || [],
	ads: adCampaign?.ads || [
		{
			name: '',
			language: AdLanguage.ENGLISH,
			video: undefined,
			playPercentage: 100,
			estimatedAdCredit: 0,
		},
	],
	language: adCampaign?.language || AdLanguage.ENGLISH,
});

export const AdvertisingAdCreate = ({ setShowCreateForm, selectedAd }) => {
	const { t } = useTranslation();

	const [step, setStep] = useState(1);
	const [error, setError] = useState();
	const [adCampaign, setAdCampaign] = useState(getInitialAdCampaign(selectedAd));
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const queryClient = useQueryClient();
	const { profile } = useProfile();

	const { mutate: createAdCampaign, isLoading: isCreateAdLoading } = useCreateAdCampaign();
	const { mutate: createDraftAdCampaign } = useCreateDraftAdCampaign();

	const isDraft = !!selectedAd?.isDraft;

	const handleCreateAd = useCallback(() => {
		if (!adCampaign.ads.reduce((acc, cur) => acc && !!cur.video, true)) {
			setError(t('AdCampaings.Ad.youMustChooseVideo'));
			return;
		}

		if (!adCampaign.ads.reduce((acc, cur) => acc && !!cur.name, true)) {
			setError(t('AdCampaings.Ad.youMustSpecifyAdName'));
			return;
		}

		createAdCampaign(adCampaign, {
			onSuccess: () => {
				setError(undefined);
				setShowCreateForm(false);
				queryClient.invalidateQueries(GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(profile?._id));
			},
			onError: (e) => {
				setError(e.response.data.message);
			},
		});
	}, [adCampaign, createAdCampaign, profile, queryClient, setShowCreateForm, t]);

	const handleCreateDraftAd = useCallback(() => {
		if (!adCampaign.name) {
			setError(t('AdCampaings.Ad.youMustSpecifyAdCampaignName'));
			return;
		}

		createDraftAdCampaign(adCampaign, {
			onSuccess: () => {
				setError(undefined);
				setShowCreateForm(false);
			},
			onError: (e) => {
				setError(e.response.data.message);
			},
		});
	}, [adCampaign, createDraftAdCampaign, setShowCreateForm, t]);

	const handleNextStep = useCallback(() => {
		setStep((prevStep) => prevStep + 1);
		setError(undefined);
	}, []);

	const handlePreviousStep = useCallback(() => {
		setStep((prevStep) => prevStep - 1);
		setError(undefined);
	}, []);

	const nextIsDisabled = useMemo(() => {
		if (step === 1 && !adCampaign?.name) {
			return true;
		}

		if (step === 2
			&& (!adCampaign?.name || adCampaign.ads.find((ad) => !ad.name || !ad.video?.video))
		) {
			return true;
		}

		return false;
	}, [adCampaign, step]);

	const handleSetStep = useCallback((newStep) => {
		if (newStep === 3
			&& (!adCampaign?.name || adCampaign.ads.find((ad) => !ad.name || !ad.video))
		) {
			return;
		}

		if (newStep === 2 && !adCampaign?.name) {
			return;
		}

		setStep(newStep);
	}, [adCampaign]);

	const isViewOnly = !!selectedAd && !isDraft;

	return (
		<div>
			<Stepper
				currentStep={step}
				setStep={handleSetStep}
				steps={[
					t('AdCampaings.Ad.campaignCriteria'),
					t('AdCampaings.Ad.adManagment'),
					t('AdCampaings.Ad.estimatedCost'),
				]}
			/>
			<Form
				schema={schema}
				data={adCampaign}
				onSubmit={() => setShowConfirmationModal(true)}
				onChange={(data) => setAdCampaign(data)}
				liveValidate
			>
				<Row className="p-0 pb-4">
					<Col className="px-4 border-top">
						{step === 1 && (
							<AdvertisingCampaignsCreate
								adCampaign={adCampaign}
								setAdCampaign={setAdCampaign}
								isViewOnly={isViewOnly}
							/>
						)}
						{step === 2 && (
							<AdvertisingAdManagmentForm
								adCampaign={adCampaign}
								setAdCampaign={setAdCampaign}
								isViewOnly={isViewOnly}
							/>
						)}
						{step === 3 && (
							<AdvertisingEstimation
								adCampaign={adCampaign}
								isViewOnly={isViewOnly}
							/>
						)}
						{!!error && (
							<Alert color="danger">
								{error}
							</Alert>
						)}
						<div className="mt-3 d-flex w-100 justify-content-between">
							<ButtonPill
								className="text-white"
								color="secondary"
								title="Accept All"
								onClick={() => setShowCreateForm(false)}
							>
								{t('AdCampaings.Ad.cancel')}
							</ButtonPill>
							<div>
								{step !== 1 && (
									<ButtonPill
										color="secondary"
										className="text-white mr-3"
										onClick={handlePreviousStep}
									>
										{t('AdCampaings.Ad.back')}
									</ButtonPill>
								)}
								{!isViewOnly && (
									<ButtonPill
										color="info"
										onClick={handleCreateDraftAd}
										className="mr-3"
										disabled={!adCampaign.name}
									>
										{t('AdCampaings.Ad.saveDraft')}
									</ButtonPill>
								)}
								{step !== 3 && (
									<ButtonPill
										color="primary"
										onClick={handleNextStep}
										disabled={nextIsDisabled}
									>
										{t('AdCampaings.Ad.next')}
									</ButtonPill>
								)}

								{step === 3 && !isViewOnly && (
									<FormSubmit
										color="primary"
										className="btn-pill"
										disabled={isCreateAdLoading}
										loading={isCreateAdLoading}
									>
										{t('AdCampaings.Ad.create')}
									</FormSubmit>
								)}
							</div>
						</div>
					</Col>
				</Row>
			</Form>
			<ConfirmationModal
				isOpen={showConfirmationModal}
				onConfirm={handleCreateAd}
				onDeny={() => setShowConfirmationModal(false)}
				message={t('AdCampaings.Ad.areYouSureYouWantToCreate')}
			/>
		</div>
	);
};

AdvertisingAdCreate.propTypes = {
	setShowCreateForm: PropTypes.func.isRequired,
	selectedAd: PropTypes.shape(),
};

AdvertisingAdCreate.defaultProps = {
	selectedAd: undefined,
};
