import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FaCrop, FaPaintBrush } from 'react-icons/fa';
import { FaUsersRectangle } from 'react-icons/fa6';
import { BsFillPipFill, BsListCheck } from 'react-icons/bs';
import { ImStatsBars2 } from 'react-icons/im';
import { RiAuctionFill } from 'react-icons/ri';
import { MdBrandingWatermark } from 'react-icons/md';

export const AppsTabType = {
	VOTES: 'votes',
	AUCTION: 'auction',
	DRAW: 'draw',
	FLOATING_PIPS: 'floatingpips',
	CROP_LIVE: 'cropLive',
	VU_METER: 'vumeter',
	SAFE_ZONE: 'safeZone',
	CANVAS_LOGO: 'canvasLogo',
};

export const AppsTabIcon = {
	[AppsTabType.VOTES]: <BsListCheck />,
	[AppsTabType.AUCTION]: <RiAuctionFill />,
	[AppsTabType.DRAW]: <FaPaintBrush />,
	[AppsTabType.FLOATING_PIPS]: <BsFillPipFill />,
	[AppsTabType.CROP_LIVE]: <FaCrop />,
	[AppsTabType.VU_METER]: <ImStatsBars2 />,
	[AppsTabType.SAFE_ZONE]: <FaUsersRectangle />,
	[AppsTabType.CANVAS_LOGO]: <MdBrandingWatermark />,
};

const AppsTabProviderContext = createContext();

export const useAppsTab = () => useContext(AppsTabProviderContext);

export const AppsTabProvider = ({ children }) => {
	const [currentTab, setCurrentTab] = useState(AppsTabType.VOTES);
	const [isSafeZoneEnabled, setIsSafeZoneEnabled] = useState(true);

	const setTab = useCallback((tab) => {
		setCurrentTab(tab);
	}, [setCurrentTab]);

	const contextValue = useMemo(() => ({
		currentTab,
		isSafeZoneEnabled,
		setIsSafeZoneEnabled,
		setTab,
	}), [
		currentTab,
		isSafeZoneEnabled,
		setIsSafeZoneEnabled,
		setTab,
	]);

	return (
		<AppsTabProviderContext.Provider value={contextValue}>
			{children}
		</AppsTabProviderContext.Provider>
	);
};

AppsTabProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
